window.$OneSignal = window.OneSignal = window.OneSignal || [];

OneSignal.push(['init', {
  "appId": "e02921e6-d7c8-4af6-a082-6778fe252e33",
  "allowLocalhostAsSecureOrigin": true,
  "autoResubscribe": true,
  "notifyButton": {
    "enable": true
  },
  "welcomeNotification": {
    "disable": true
  }
}]);

export default function (ctx, inject) {
  inject('OneSignal', OneSignal)
}
