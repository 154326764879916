const getPerformerImageUrl =(  slug)=> {
    return `https://firebasestorage.googleapis.com/v0/b/peppertix-org.appspot.com/o/${slug}%2Fthumb%40target.jpg?alt=media&token=ffdad2eb-c055-49d2-b56d-312476a58fa6`
  }
  
 const getPerformerMiniImageUrl = (slug) =>  {
    return `https://firebasestorage.googleapis.com/v0/b/peppertix-org.appspot.com/o/${slug}%2Fthumb%40search.jpg?alt=media&token=ffdad2eb-c055-49d2-b56d-312476a58fa6`
  }
  
  export default {
    getPerformerImageUrl,
    getPerformerMiniImageUrl
  }