import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";




/**
 *
 * @param {*} param0
 * @param {*} params
 */
var getFriendsInfo = (id) => {
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/user_web/${id}/friends`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .get(consulta)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {

        reject(error);
      });
  })
}





const getMembership = (userId, token) => {
  let consulta = `${appBaseUrl}api/user/${userId}/membership`;
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return instance.get(consulta)
}

const sendPromoPartnerEmail = (userId, token, body = {
  code: '',
  email: '',
  firstName: '',
}) => {
  let consulta = `${appBaseUrl}api/user/${userId}/send_promo_partner_email`;
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`


  return instance.post(consulta, body)
}



/**
 *
 * @param {*} id
 * @param {*} token
 */
var contactInfo = (id, token) => {
  let consulta = `${appBaseUrl}db/user_web/${id}/contactInfo`;
  let instance = axios.create();
  instance.defaults.headers.common = {};
  instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  //instance.defaults.timeout = 9000;
  return instance.get(consulta)

}





export default {
  getFriendsInfo,
  getMembership,
  contactInfo,
  sendPromoPartnerEmail

}
