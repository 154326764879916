/* eslint-disable */
import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";

var getEvent = async (eventID) => {
 
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}v1/eventbrite/events/${eventID}` ;
  let data = {
  }
  try {
    let response = await instance.get(`${url}`)
    data = response.data

  } catch (error) {
    
  }

  return data


}


var getEventDescription = async (eventID) => {
 
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}v1/eventbrite/events/${eventID}/description` ;
  let data = {
  }
  try {
    let response = await instance.get(`${url}`)
    data = response.data

  } catch (error) {
    
  }

  return data


}





var getEvents = async (params) => {
 
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}v1/eventbrite/events?${querystring.stringify(params)}` ;
  console.log(url)
  let data = {
  }
  try {
    let response = await instance.get(`${url}`)
    data = response.data
    console.log(data)
  } catch (error) {
    
  }

  return data


}


var getEventsBySeriesId = async (id) => {
 
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}v1/eventbrite/series/${id}/events` ;
  console.log(url)
  let data = {
  }
  try {
    let response = await instance.get(`${url}`)
    data = response.data
    console.log(data)
  } catch (error) {
    
  }

  return data


}



export default {
  getEvent,
  getEvents,
  getEventsBySeriesId,
  getEventDescription
}

