/* eslint-disable */
import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";





/**
 *
 * @param {*} param0
 * @param {*} params
 */
var searchPerformerByQuery = async (params, token) => {
 

    //http://localhost:8888/tevo/performers/query/

    let consulta = `${appBaseUrl}tevo/performers/query/${querystring.stringify(
      params
    )}`;


    let instance = axios.create();
    instance.defaults.headers.common = {};


    //instance.defaults.timeout = 9000;


    let response = {
      data: {
        performers: []
      }
    }
    try {
       response = await instance.get(consulta)
    } catch (error) {
      console.log(`Error searchPerformerByQuery ${error} `)
    }

    return response
    
  
}


/**
 *
 * @param {*} param0
 * @param {*} params
 */
var getLocationInfo = async (ip) => {
 
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let consulta = `${appBaseUrl}api/geo`;
    if (ip != undefined) {
      consulta += `?ip=${ip}`
    }
 
    let response = {
      data: {
        latitude: 0,
        longitude: 0
      }
    }

    try {
      response = await instance.get(consulta)
    } catch (error) {
      console.log(`Error getLocationInfo ${error} `)
    }

    return response 

  


 
}

var searchMessengerUser = (params) => {
  //http: //localhost:3069/db/user_datas/1705877032758862
  return new Promise((resolve, reject) => {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let consulta = `${appBaseUrl}db/user_datas/${params.senderId}`;
    instance
      .get(consulta)
      .then(response => {
        resolve(response);
      })
      .catch(error => {

        reject(error);
      });
  })
}




export default {
  searchPerformerByQuery,
  searchMessengerUser,
  getLocationInfo
}
