import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";




/**
 *
 * @param {*} param0
 * @param {*} params
 */
var newInvitation = (invitation) => {

  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation/`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .post(consulta, invitation)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })
}

var getInvitation = (params) => {
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation?${querystring.stringify(params)}`;

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .get(consulta)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })

}

var updateInvitacionAddFriends = (params, invitedFriends = []) => {
  /*let inviterId = req.query.inviterId;
  let eventId = req.query.eventId;
  let invitedFriends = req.body.invitedFriends*/
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation/invitedfriends?${querystring.stringify(params)}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .put(consulta, {
        invitedFriends
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {        
        reject(error);
      });
  })
}


var updateReceiverStatus = (params) => {
  /**
   *{
     "inviterId": "5b536301c229cc00146a1bb2",
     "eventId": "1425592",
     "emailRx": "armandorussi@gmail.com",
     "status": "Going"
   }
   */
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation/receiver/status`;
  
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .put(consulta, params)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })
}



var unInvitedFriend = (params) => {

  return new Promise((resolve, reject) => {

    /*let inviterId = req.query.inviterId;
    let eventId = req.query.eventId;
    let email = req.query.email*/
    let consulta = `${appBaseUrl}db/invitation/uninvitedfriend?${querystring.stringify(params)}`;


    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .delete(consulta)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })
}







var updateInvitacionEventId = (id, event) => {
  /* let inviterId = req.query.inviterId;
 let eventId = req.query.eventId;
 */
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation/${id}/event`
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .put(consulta, {
        event
      })
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })
}




var getReceiverInvitation = (params) => {
  return new Promise((resolve, reject) => {
    let consulta = `${appBaseUrl}db/invitation/receiver?${querystring.stringify(params)}`

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
    //instance.defaults.timeout = 9000;
    instance
      .get(consulta)
      .then(response => {
        resolve(response.data);
      })
      .catch(error => {
        reject(error);
      });
  })

}


export default {
  newInvitation,
  updateInvitacionAddFriends,
  updateInvitacionEventId,
  unInvitedFriend,
  getInvitation,
  getReceiverInvitation,
  updateReceiverStatus
}
