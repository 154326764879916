// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/avenir/AvenirLTStd-Book.otf");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/avenir/AvenirLTStd-Light.otf");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/avenir/AvenirLTStd-Roman.otf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/inter/Inter-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../assets/fonts/cooper-hewitt/CooperHewitt-Medium.otf");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../assets/fonts/bebas_neue/BebasNeue-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../assets/fonts/segoe-ui/Segoe-UI.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-display:swap;font-family:\"Roboto\";font-style:normal;font-weight:300;src:url(https://fonts.gstatic.com/s/roboto/v27/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format(\"woff2\")}@font-face{font-display:swap;font-family:\"Material Icons\";font-style:normal;font-weight:400;src:url(https://fonts.gstatic.com/s/materialicons/v85/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format(\"woff2\")}@font-face{font-display:swap;font-family:\"Avenir\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"opentype\")}@font-face{font-display:swap;font-family:\"Avenir\";font-style:light;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"otf\")}@font-face{font-display:swap;font-family:\"Avenir\";font-style:roman;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"otf\")}@font-face{font-display:swap;font-family:\"Inter\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"ttf\")}@font-face{font-display:swap;font-family:\"CooperHewitt\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"opentype\")}@font-face{font-display:swap;font-family:\"BebasNeue-Regular\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"opentype\")}@font-face{font-display:swap;font-family:\"Segoe-UI\";font-style:normal;font-weight:400;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"opentype\")}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
