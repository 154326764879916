/* eslint-disable */
//const appBaseUrl = 'https://peppertix-backend.herokuapp.com/';
const appFrontUrl = process.env.APP_FRONT_URL || 'http://localhost:3070' //'https://www.peppertix.org' //'https://peppertix-frontend.herokuapp.com/'; //produccion: https://www.peppertix.org/   |   pruebas:  https://peppertix-frontend.herokuapp.com/        local:http://localhost:3070/
//const appBaseUrl = 'http://localhost:3069/'; //produccion: https://peppertix-backend.herokuapp.com/   local:http://localhost:3069/


const appBaseUrl = process.env.baseUrl;


const OXXO_ENABLE = process.env.OXXO_ENABLE;
const PUSH_NOTIFICATION_ENABLE = process.env.PUSH_NOTIFICATION_ENABLE;
const PAYPAL_ENABLE = process.env.PAYPAL_ENABLE;




const terms_of_service = '/legal/terms_of_service';
const privacy_policy = '/legal/privacy_policy';

const license =
  'http://documents.ticketevolution.com/legal/Broker_Licenses.txt?utm_source=Affiliates+Email+List&utm_campaign=a0c35cfcf2-EMAIL_CAMPAIGN_2019_01_07_06_08&utm_medium=email&utm_term=0_9895cc463e-a0c35cfcf2-420257033';

//====================
const API_REST_SECRET = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZGVudGlmaWNhY2lvbkRQYWdpbmEiOiI5MTUwNzc1OCIsIm5vbWJyZURlUGFnaW5hIjoiUGVwcGVyLUJvdC1XZWItUGFnZSIsImlhdCI6MTUxNjIzOTAyMn0.iQe5BEC_728Ti7HxH300T_h7ssICDzvUu6-B-betn-Q'

//====================
const enableInvitation = false;

var FB_APP_PUBLIC_ID = '1580071112297891'; //"1276771255782201"; //process.env.FB_APP_PUBLIC_ID;1580071112297891
var FB_APP_SECRET_ID = 'e6ee0ca2a8685386e6dd53943d1e9f93'; //"e6ee0ca2a8685386e6dd53943d1e9f93"; //process.env.FB_APP_SECRET_ID;


const SP_CLIENT_ID = process.env.SP_CLIENT_ID
const SP_CLIENT_SECRET = process.env.SP_CLIENT_SECRET


let factorFees = 0.21
let factorFeesIncreased = 100 / (100 - factorFees * 100)


export default {
  API_REST_SECRET,
  appBaseUrl,
  appFrontUrl,
  FB_APP_PUBLIC_ID,
  FB_APP_SECRET_ID,
  terms_of_service,
  privacy_policy,
  enableInvitation,
  license,
  OXXO_ENABLE,
  PAYPAL_ENABLE,
  PUSH_NOTIFICATION_ENABLE,
  factorFees: 0.21,
  factorFeesIncreased,
  SP_CLIENT_ID,
  SP_CLIENT_SECRET,

};
