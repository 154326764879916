import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";



var getBDPerformerById = async (performer_id) => {
 
    let searchPerformerById = `${appBaseUrl}db/artist/${performer_id}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;

    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${searchPerformerById}`)
    }catch(error){

    }


    return response 

    
 
}

var actionGetPerformerByName = async (name) => {
 
    let searchPerformerByName = `${appBaseUrl}tevo/performer-name/${name}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${searchPerformerByName}`)
    }catch(error){

    }


    return response 
}


var updatePerformerById = async (performer_id, cambiar = false) => {
   
    let ruta = `${appBaseUrl}tevo/performer-update-byid/${performer_id}?cambiar=${cambiar}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${ruta}`)
    }catch(error){

    }


    return response 
}





var getTevoPerformerById = async (id) => {
 
    let searchTevoPerformerById = `${appBaseUrl}tevo/performer/${id}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${searchTevoPerformerById}`)
    }catch(error){

    }


    return response 
}


var getPerformerByName = async (name) => {
 
    let searchPerformerByName = `${appBaseUrl}db/artist?name=${name}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.timeout = 9000;
    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${searchPerformerByName}`)
    }catch(error){
      if (error.code === 'ECONNABORTED') {
        console.log('Error getPerformerByName: The request time out.');
      } else {
        console.log(`Error getPerformerByName:  en la solicitud: ${error.message}`);
      }
    }


    return response 
}

var getBDPerformerLikeName = async (name) => {
  
    let searchPerformerByName = `${appBaseUrl}db/artists/${name}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let response = {
      data: {}
    }

    try{
      response =  await instance.get(`${searchPerformerByName}`)
    }catch(error){

    }


    return response 
}


var searchPerformersLikeName = async (params) => {
 
    let searchPerformerByName = `${appBaseUrl}db/performers?${querystring.stringify(params)}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let data = []
    try{
      let response =  await instance.get(`${searchPerformerByName}`)
      data = response.data

    }catch(error){

    }
    

    return data 
 
}


//http://localhost:3069/db/artists_in/[55191,28007,1847,4113,33687,52539]
var getArtistsByPerformersId = async (matriz_ids) => {
 
    let searchPerformerByName = `${appBaseUrl}db/artists_in/${matriz_ids}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let data 

    try {
      let response = await instance
      .get(`${searchPerformerByName}`)
      data = response.data
    } catch (error) {
      
    }

    return data
     
  
}



var getPerformersSearch = async (params) => {
 
    let searchTevoPerformerById = `${appBaseUrl}tevo/performers/search?${querystring.stringify(
      params
    )}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.timeout = 9000;
    let response = {
      data: {
        performers: []
      }
    }
 


    try{
      response  = await instance
      .get(`${searchTevoPerformerById}`)
    }catch(error){
      if (error.code === 'ECONNABORTED') {
        console.log('Error getPerformersSearch: The request time out');
      } else {
        console.log(`Error getPerformersSearch:  ${error.message}`);
      }
    }

    return response 
 
 
  
}

var getPerformers = async (params) => {
 
    let searchTevoPerformerById = `${appBaseUrl}tevo/performers?${querystring.stringify(
      params
    )}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;


    let response = {
      data: {}
    }

    try {
      response = await instance
      .get(`${searchTevoPerformerById}`)
    } catch (error) {
      
    }

    return response 

    
}



var loadingClassF = (performer) => {
  let categoria_padre = 1
  let loadingClass = "loading_sport";
  if (performer != undefined) {
    if (performer.id != undefined) {
      if (performer.category) {
        if (performer.category.id) {
          if (performer.category.parent) {
            if (performer.category.parent.parent) {
              if (performer.category.parent.parent.id) {
                categoria_padre = performer.category.parent.parent.id;
              } else {
                categoria_padre = performer.category.parent.id;
              }
            } else {
              categoria_padre = performer.category.parent.id;
            }
          } else {
            categoria_padre = performer.category.id;
          }
        }
      }

      switch (categoria_padre) {
        case 1:
          {
            loadingClass = "loading_sport";
            performer.imageUrl = "/img/no-image/no_image_sport.jpg";
          }
          break;
        case 54:
          {
            loadingClass = "loading_concert";
            performer.imageUrl = "/img/no-image/no_image_concert.jpg";
          }
          break;
        case 68:
          {
            loadingClass = "loading_theatre";
            performer.imageUrl = "/img/no-image/no_image_theater.jpg";
          }
          break;
        case 73:
          {
            loadingClass = "loading_special";
            performer.imageUrl =
              "/img/no-image/no_image_special_event.jpg";
          }
          break;
        default:
          {
            loadingClass = "loading_concert";
            performer.imageUrl = "/img/no-image/no_image_concert.jpg";
          }
          break;
      }
    
    }
  } else { }

  return {
    categoria_padre,
    loadingClass
  }
}

var getMorePopularPerformers = async (params) => {
 
    let searchTevoPerformerById = `${appBaseUrl}pr/morepopular_performers`
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;


    let performers = []

    try {
      let response = await instance
      .get(`${searchTevoPerformerById}`)
      performers = response.data
    } catch (error) {
      
    }

 
    for (let i = 0; i < performers.length; i++) {
      performers[i].id = performers[i].performer_id;
      performers[i].venue = {
        location: '',
        name: '',
      };

      performers[i].activado = false;
      performers[i].isFavorite = false;
      performers[i].occurs_at_format = '';
    }


    return performers

  
}

export default {
  actionGetPerformerByName,
  getPerformerByName,
  updatePerformerById,
  getTevoPerformerById,
  getBDPerformerById,
  getArtistsByPerformersId,
  getPerformersSearch,
  getBDPerformerLikeName,
  getPerformers,
  loadingClassF,
  searchPerformersLikeName,
  getMorePopularPerformers
}
