/* eslint-disable */

import globalAxios from "axios";
import configVars from "../config/config_vars";
const appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

function generateSortFn(prop, reverse) {
  return function (a, b) {
    if (a[prop] < b[prop]) return reverse ? 1 : -1;
    if (a[prop] > b[prop]) return reverse ? -1 : 1;
    return 0;
  };
}

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  getMusicCategoriesByName({
    commit,
    dispatch
  }, name) {
    return new Promise((resolve, reject) => {
      let searchMusicCategoryLike = `${appBaseUrl}db/music/`;
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchMusicCategoryLike}${name}`)

        .then(response => {
          let categories = response.data;
      
          resolve(categories);
        })
        .catch(error => {
     
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  getCategoryByName({
    commit,
    dispatch
  }, name) {
    return new Promise((resolve, reject) => {
      let searchCategoryByName = `${appBaseUrl}db/category/`;
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchCategoryByName}${name}`)

        .then(response => {
          let categories = response.data;
         
          resolve(categories);
        })
        .catch(error => {
         
        });
    });
  }
};
