import api from "@/api/api";
export default function (context) {

  if (process.server && context.req) {
    if (context.req.query && context.req.query.senderId) {
     
      let parametros = {
        senderId: context.req.query.senderId
      }
      api.searchMessengerUser(parametros).then((response) => {
        if (response.data) {
          let email = response.data.email
          let password = response.data.password
          let tipo = response.data.tipo
          let messengerUser = {
            email,
            password,
            tipo,
            senderId: context.req.query.senderId
          }
          context.store.commit("user/storeMessengerUser", messengerUser)
         
        }
      })
    }
  }
  if (process.client) {
    
  }
}
