
export default {
  props: ["error"],
  layout: "blog", // you can set a custom layout for the error page
  methods: {
    ClickBody() {
      this.$store.commit("ClickBody", true);
 
    }
  }
};
