/* eslint-disable */

import configVars from "../config/config_vars";
const appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";

function generateSortFn(prop, reverse) {
  return function (a, b) {
    if (a[prop] < b[prop]) return reverse ? 1 : -1;
    if (a[prop] > b[prop]) return reverse ? -1 : 1;
    return 0;
  };
}

export const state = () => ({
  performer: null,
  morePopularPerformers: [],
  performersByName: [],
  artistsWithOnComingEvents: [],
  searchedArtist: null
});

export const getters = {
  performer(state) {
    return state.performer;
  },
  morePopularPerformers(state) {
    return state.morePopularPerformers;
  },
  getPerformersSearchedByName(state) {
    return state.performersByName;
  },
  getArtistsWithOnComingEvents(state) {
    return state.artistsWithOnComingEvents;
  },
  searchedArtist(state) {
    return state.searchedArtist
  }
};

export const mutations = {
  storePerformer(state, performer) {
    state.performer = performer;
  },
  storePerformersSearchedByName(state, performers) {
    state.performersByName = performers;
  },
  sotoreArtistsWithOnComingEvents(state, artistas) {
    state.artistsWithOnComingEvents = artistas;
  },
  setSearchedArtist(state, artist) {
    state.searchedArtist = artist;
  },
  storeMorePopularPerformers(state, performers) {
    state.morePopularPerformers = performers;
  },

};

export const actions = {


  /**
   *
   * @param {*} param0
   * @param {*} name
   */
  actionGetPerformerByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchPerformerByName = `${appBaseUrl}tevo/performer-name/${name}`;

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchPerformerByName}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
        
        });
    });
  },

  /**
   * @description getPerformerById
   *
   * db / artist ? name = Shakira
   */
  getPerformerById({
    commit,
    dispatch,
    rootState
  }, id) {
    return new Promise((resolve, reject) => {
      let searchArtist = `${appBaseUrl}db/artist/`;
      axios
        .get(`${searchArtist}${id}`)
        .then(performer => {
          resolve(performer);
        })
        .catch(error => {
          reject(error);
         
        });
    });
  },

  /**
   * @description getPerformerById
   *
   * db/artist?name=Shakira
   */
  getPerformerByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchArtist = `${appBaseUrl}db/artist?name=`;
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchArtist}${name}`)
        .then(performer => {
          resolve(performer);
        })
        .catch(error => {
          reject(error);
          
        });
    });
  },


  storePerformerByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchArtist = `${appBaseUrl}db/artist?name=`;

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchArtist}${name}`)
        .then(performer => {
          commit("storePerformer", performer.data)
          resolve(performer.data)
        })
        .catch(error => {
          reject(error);
        
        });
    });
  },


  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  setPerformersByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchArtistsLike = `${appBaseUrl}db/artists/`;

      let instance = axios.create();
      instance.defaults.headers.common = {};
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchArtistsLike}${name}`)
        .then(response => {
          let artistsSelected = [];
          let artists = [];

          let performers = response.data;
          for (let i = 0; i < performers.length; i++) {
            let imageUrl = "";

            for (let j = 0; j < performers[i].images.length; j++) {
              if (performers[i].images[j].size === "300X300") {
                imageUrl = performers[i].images[j].url;
              } else if (performers[i].images[j].width) {
                imageUrl = performers[i].images[0].url;
              }
            }

            if (imageUrl === "" || imageUrl.charAt(4) != "s") {
              imageUrl = `${appBaseUrl}img/no_artist_images_300x300.jpg`;
            }

            let activado = false;
            if (performers[i].activado) {
              activado = true;
            }

            let artist = {
              name: performers[i].name,
              performer_id: performers[i].performer_id,
              activado: activado,
              imageUrl: imageUrl,
              images: performers[i].images,
              popularity_score: performers[i].popularity_score
            };
            if (artist.performer_id && performers[i].performer_id >= 1) {
              artists.push(artist);
            }
          }
          resolve(artists);
          //commit("storePerformersSearchedByName", artists);
        })
        .catch(error => {
          resolve([]);
         
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  searchSportsByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchSportsLike = `${appBaseUrl}db/sports/`;

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchSportsLike}${name}`)
        .then(response => {
          let sports = response.data;

          resolve(sports);
        })
        .catch(error => {
          resolve([]);
          
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  searchTheatreByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchSportsLike = `${appBaseUrl}db/theatre/`;
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchSportsLike}${name}`)
        .then(response => {
          let sports = response.data;
          resolve(sports);
        })
        .catch(error => {
          resolve([]);
        
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description buscar los performers by name
   */
  searchSpecialEventsByName({
    commit,
    dispatch,
    rootState
  }, name) {
    return new Promise((resolve, reject) => {
      let searchSportsLike = `${appBaseUrl}db/special_events/`;
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchSportsLike}${name}`)
        .then(response => {
          let sports = response.data;
          resolve(sports);
        })
        .catch(error => {
          resolve([]);
       
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   *
   */
  actionUpdatePerformer({
    commit,
    dispatch,
    rootState
  }, params) {
    let ruta = `${appBaseUrl}tevo/performer-update/${params.performer_id}`;
    
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${ruta}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
          
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   *
   */
  actionUpdatePerformerById({
    commit,
    dispatch,
    rootState
  }, params) {
    let ruta = `${appBaseUrl}tevo/performer-update-byid/${params.performer_id}`;
    
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${ruta}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   *
   */
  actionUpdatePerformerName({
    commit,
    dispatch,
    rootState
  }, params) {
    let ruta = `${appBaseUrl}tevo/performer-update-byname/${params.name}`;
  
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${ruta}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} page
   * @param {*} per_page
   */
  setArtistsWithOnComingEvents({
    commit,
    dispatch,
    rootState
  }, params) {
    let ruta = `${appBaseUrl}tevo/performers_with_events/page/${
      params.page
    }/per_page/${params.per_page}`;

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
    //instance.defaults.timeout = 9000;

    instance.get(`${ruta}`).then(response => {
      let artists = [];
    
      let performers = response.data;
      for (let i = 0; i < performers.length; i++) {
        let imageUrl = "";

        for (let j = 0; j < performers[i].images.length; j++) {
          if (performers[i].images[j].size === "300X300") {
            imageUrl = performers[i].images[j].url;
          } else if (performers[i].images[j].width) {
            imageUrl = performers[i].images[0].url;
          }
        }

        if (imageUrl === "" || imageUrl.charAt(4) != "s") {
          imageUrl = `${appBaseUrl}images/no_artist_images_300x300.jpg`;
        }

        let activado = false;
        if (performers[i].activado) {
          activado = true;
        }

        let artist = {
          name: performers[i].name,
          performer_id: performers[i].performer_id,
          activado: activado,
          imageUrl: imageUrl,
          images: performers[i].images,
          popularity_score: performers[i].popularity_score,
          event_url: performers[i].event_url,

          event_fb_url_encode: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            performers[i].event_url
          )}&amp;src=sdkpreparse`,
          venue_name: performers[i].venue_name
        };
        if (artist.performer_id) {
          artists.push(artist);
        }
      }

      artists.sort(generateSortFn("popularity_score", true));

      commit("sotoreArtistsWithOnComingEvents", artists);
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  searchPerformerLikeName({
    commit,
    dispatch,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {

      //http://localhost:8888/tevo/performers/like/q=bruno&page=1&per_page=2
      //let consulta = `${appBaseUrl}tevo/performers/like/${querystring.stringify(params)}`;

     

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(consulta)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
         
          reject(error);
        });
    });
  },
  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  searchPerformerByQuery({
    commit,
    dispatch,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {

      //http://localhost:8888/tevo/performers/query/
      let consulta = `${appBaseUrl}tevo/performers/query/${querystring.stringify(
        params
      )}`;

      consulta = `http://localhost:8888/tevo/performers/query/${querystring.stringify(
        params
      )}`;
    
      consulta = `${appBaseUrl}tevo/performers/query/${querystring.stringify(
        params
      )}`;
  

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      instance
        .get(consulta)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
         
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  searchPerformersQueries({
    commit,
    dispatch,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {

      //http://localhost:8888/tevo/performers?category_id=54&category_tree=true&only_with_upcoming_events=true&order_by=performers.popularity_score%20DESC&page=1&per_page=12"
      let consulta = `${appBaseUrl}tevo/performers?${querystring.stringify(
        params
      )}`;

    

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(consulta)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
        
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  searchPerformerById({
    commit,
    dispatch,
    rootState
  }, params) {
    return new Promise((resolve, reject) => {

      //http://localhost:8888/tevo/performer/10102?include=true
      let consulta = `${appBaseUrl}tevo/performer/${params.id}?include=${
        params.include
      }`;

  
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(consulta)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          
          reject(error);
        });
    });
  }
};
