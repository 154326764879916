import apiBraintree from "@/api/braintree";

//este middleware valida si el usuario tiene subscription activa  o no

export default async (context) => {
    const {
        app,
        store,
        route,
        params,
        query,
        env,
        isDev,
        isHMR,
        redirect,
        error
    } = context

    if (process.server && context.req) {

        const isSubscriptionActivated = process.env.IS_SUBSCRIPTIONS_ACTIVATED === 'true'
        console.log(`isSubscriptionActivated ${isSubscriptionActivated}`)

        if (route.path.includes('/subscription') && !isSubscriptionActivated) {
            return context.redirect(`/`)
        }

        if (route.path.startsWith('/subscription/') && route.path !== '/subscription') {

            if (!context.store.getters["user/user"]) {
                await context.store.dispatch("user/tryAutoSignin", context.req);
            }
            if (!context.store.getters["user/user"]) {
                return context.redirect(`/?login=true`);
            }

            const userId = context.store.getters["user/user"]._id
            const idToken = context.store.getters["user/idToken"]
            if (!userId) {
                return
            }


            const { data } = await apiBraintree.isSubscriptionActive(userId, idToken)


            if (data.active == true && route.path.includes('subscription/checkout')) {
                return context.redirect(`/user/subscription`)
            }

            if (data.active == false && route.path.includes('subscription/my-subscription')) {
                return context.redirect(`/subscription/checkout`);
            }


        }



    }
    if (process.client) {

    }
}
