export default async function (context) {
  const {
    app,
    store,
    route,
    params,
    query,
    env,
    isDev,
    isHMR,
    redirect,
    error
  } = context
  if (route.path.includes('/user')) {
    if (!context.store.getters["user/user"]) {
      await context.store.dispatch("user/tryAutoSignin", context.req);
      if (!context.store.getters["user/user"]) {
        return context.redirect(`/?login=true`);
      }
    }
  }
}
