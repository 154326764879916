// plugins/crisp.js
export default ({ app, route }, inject) => {
    if (process.client) {
      // Función para verificar si la ruta actual coincide con los patrones deseados
      const shouldShowChat = () => {
        return route.path.match(/^\/book\/[^\/]+\/[^\/]+\/[^\/]+$/) ||
               route.path.match(/^\/checkout\/\d+$/);
      };
  
      // Verificar si se debe cargar Crisp para la ruta actual
      if (shouldShowChat()) {
        // Crear el script de Crisp
        (function () {
          const d = document;
          const s = d.createElement('script');
          s.src = 'https://client.crisp.chat/l.js';
          s.async = 1;
          d.getElementsByTagName('head')[0].appendChild(s);
        })();
  
        // Configuración de Crisp
        window.$crisp = [];
        window.CRISP_WEBSITE_ID = '8a695e01-72f2-4ae4-bf85-80e6cec85c7b'; // Reemplaza con tu ID de Crisp

      }
    }
  };
  

// function initCrisp() {
//     window.$crisp = [];
//     window.CRISP_WEBSITE_ID = "8a695e01-72f2-4ae4-bf85-80e6cec85c7b";
//     (function () {
//       let d = document;
//       let s = d.createElement("script");
//       s.src = "https://client.crisp.chat/l.js";
//       s.async = 1;
//       d.getElementsByTagName("head")[0].appendChild(s);
//     })();
//   }
  
//   export default {
//     initCrisp
//   }
  
  