  const groupEventsByName = (events) => {
  // Object to store grouped events
  const groupedEvents = {};
  
  // Iterate over the events
  events.forEach(event => {
    const { name, occurs_at } = event;
  
    // Check if the event already exists in the grouped events
    if (groupedEvents[name]) {
      // Compare the dates and update if the new date is closer
      const currentDateTime = parseInt(groupedEvents[name].occurs_at);
      const newDateTime = parseInt(occurs_at);
  
      if (newDateTime > currentDateTime) {
        groupedEvents[name].occurs_at = occurs_at;
      }
    } else {
      // If the event doesn't exist in the grouped events, add it
      groupedEvents[name] = event;
    }
  });
  
  // Get the grouped events in an array
  const groupedEventsArray = Object.values(groupedEvents);
  
  console.log(groupedEventsArray )
  return  groupedEventsArray
  
 }

 export default {
    groupEventsByName
 }