// facebookMixin.js
import facebook from '@/api/facebook';

export default {
    data() {
        return {
            senderId: null,
        };
    },
    methods: {

        async handleFacebookResponse() {
            const query = this.$route.query;
            const accessToken = query.access_token;
            const signin_with = query.signin_with;

            if (accessToken && signin_with === 'facebook') {
                await this.facebookMe(accessToken);
                const url = new URL(window.location.href);
                url.searchParams.delete('access_token');
                url.searchParams.delete('signin_with');
                url.searchParams.delete('v');
                window.history.replaceState({}, document.title, url);
            }


        },
        async facebookMe(accessToken) {
            try {
                const userResponse = await fetch(`https://graph.facebook.com/me?access_token=${accessToken}&fields=id,picture.width(720).height(720),name,first_name,last_name,birthday,email,address,music,favorite_teams,about`);

                if (userResponse.ok) {
                    const userData = await userResponse.json();
                    const music = userData.music?.data?.map(item => item.name) || [];
                    const userToSave = {
                        firstName: userData.first_name,
                        lastName: userData.last_name,
                        email: userData.email,
                        password: userData.email,
                        birthday: userData.birthday,
                        imgSrc: userData.picture.data.url,
                        music,
                        senderId: this.senderId,
                        fbId: userData.id,
                        tipo: "facebook",
                        fbFriendsIds: [], // Asigna amigos si es necesario
                    };

                    await this.$store.dispatch("user/signup", userToSave);
                    this.handleSuccessfulSignup();
                } else {
                    console.log('Error en la respuesta de Facebook:', userResponse.status, userResponse.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        handleSuccessfulSignup() {

            this.$store.commit("user/signinGetReadyToAddPromo", false);

            const user = this.$store.getters["user/user"];
            if (user) {
                const coordinates = this.$store.getters["coordinates"];
                if (coordinates && coordinates.lat && coordinates.lon) {
                    this.$store.dispatch("user/setUserCoordinates", coordinates);
                }

                this.$gtm.push({
                    event: "FBSigning",
                    fbsigning: {
                        email: user.email,
                    },
                });
            }
        }
    }
};
