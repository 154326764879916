
import { mapMutations, mapGetters } from "vuex";


import invitationsApi from "@/api/invitations";
import configVars from "@/config/config_vars";

import performersApi from "@/api/performers";
import categoriesApi from "@/api/categories";
import citiesApi from "@/api/cities";

import windowScrollPosition from '@/mixins/window-scroll-position'
import changeOpacityMixin from '@/mixins/change_opacity_icons'

import categoriasMenu from "@/api/categorias/categorias";
import scroll from '@/util/scroll'
import isValidHomeUrl from '@/util/isValidHomeUrl'

import chatBubbleMixin from '@/mixins/chatBubble';

let appFrontUrl = configVars.appFrontUrl;
let enableInvitation = configVars.enableInvitation;

export default {
  mixins: [
    windowScrollPosition('position'),
    changeOpacityMixin(),
    chatBubbleMixin
  ],
  computed: {
    ...mapGetters({
      user: "user/user",
      userIsVIPMember: "user/userIsVIPMember",
      idToken: "user/idToken",
      isAutenticated: "user/isAutenticated",
      prospect: "user/prospect",
      imgSrc: "user/imgSrc",
      isClickBody: "isClickBody",
      receivedInvitations: "user/receivedInvitations",
      media_match: "media_match",
      openMobileSearch: "openMobileSearch",
      openCategoryMenu: 'openCategoryMenu',
      magic_flag: 'magic_flag',
      tooglePromoRegister: "tooglePromoRegister",
      toogleSignIn: "toogleSignIn",
      signUpModePromo: "signinform/signUpModePromo",
      zIndexClass: "zIndexClass"
    }),
 

  },

  created() {
    /*this.$store
      .dispatch("user/fetchUser")
      .then(user => {})
      .catch(error => {});*/
    //this.$store.commit("performers/setSearchedArtist", this.$route.params.name);
  },
  mounted() {

    this.$nextTick(() => {

      window.addEventListener('resize', () => {
        // We execute the same script as before
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      });

      scroll.blockScroll(false)


    })
  },
  components: {},
  subscriptions() { },
  data() {
    return {

      showTransparentBox: false,
      concertsMenu: categoriasMenu.concerts,
      sportsMenu: categoriasMenu.sports,
      specialEventsMenu: categoriasMenu.special_events,
      theatreMenu: categoriasMenu.theatre,
      basketBallCategories: categoriasMenu.basketBallCategories,
      footballCategories: categoriasMenu.footballCategories,
      baseballCategories: categoriasMenu.baseballCategories,
      fightingCategories: categoriasMenu.fightingCategories,
      soccerCategories: categoriasMenu.soccerCategories,
      hockeyCategories: categoriasMenu.hockeyCategories,
      showInner: true,
      drawer: false,
      search_query: "",
      blockearSearch: false,
      selectedMatched: {},
      busquedaMovil: '',
      enableInvitation: enableInvitation,
      busqueda: "",
      performer: {},
      invitationSelected: {},
      showMenuOnIcon: false,
      toogleShowBackPage: true,
      toogleInvitation: false,

      toogleInviteFriends: false,
      toogleSignUp: false,

      show_search_action: true,
      show_header_search: false,
      items: [
        {
          title: "Click Me"
        },
        {
          title: "Click Me"
        },
        {
          title: "Click Me"
        },
        {
          title: "Click Me 2"
        }
      ],
      active: false,
      show_menu_status: false,
      musicCategories: [],
      searchedArtists: [],
      searchedEvents: [],
      cities: [],

      indexSelected: 0,
      overCategories: true,
      overPerformers: false
      //imgSrc: "/static/img/home/profile.svg"
    };
  },
  props: {
    cursorTitle: {
      type: String,
      default: 'pointer'
    },
    openSignUpF: Function,
    openSignInF: Function,
    goBack: Function,
    title: {
      type: String,
      default: "Pepper Tix"
    },
    hasArrowBack: {
      type: Boolean,
      default: false
    },
    slug: {
      type: String,
      default: '',

    },
    searchVisible: {
      type: Boolean,
      default: true,
    },
    top: {
      type: Number,
      default: 0,
    },
  },

  watch: {

    openMobileSearch(newVal) {
      if (newVal == true) {
        this.showsearch()
        this.$store.commit("openMobileSearch", false);
        this.changeOpacity('1');
      }
    },
    position(newVal) {
      //console.log('position changed :::: ' + this.$route.path)
    
      const currentPath = this.$route.path;

      this.showInner = true
    
      //console.log('evaluate :::: ' + !( currentPath === '/'  || isValidHomeUrl.isValidHomeUrl(this.$route.path) ) )
    
      if ( !( currentPath === '/'  || isValidHomeUrl.isValidHomeUrl(this.$route.path) ) ) {
         
          return
      }  

      // if (this.$route.path != '/') {
      //   return
      // }

      //this.showInner = false
      if (newVal[1] > 200) {
        this.showInner = true
      } else {
        this.searchback()
        this.showInner = false
      }
      console.log('this.showInner ::: ' + this.showInner)
    },
    isClickBody: function (val, oldVal) {
      console.log(`val ${val}`)

      if (val == true) {

        this.active = false;
        this.toogleInvitation = false;
        this.showMenuOnIcon = false;

        //this.$store.commit("toogleSignIn", false )
        //scroll.blockScroll(false)

      } else if (val == false) {

        //scroll.blockScroll(true)
      }
    },
    openCategoryMenu: function (val, oldvVal) {
      if (val == true) {
        this.showMenuOnIcon = false;
        this.active = false;
      }
    },
    toogleInvitation: function (val, oldVal) {
      if (val == true) {
        this.$store.commit("ClickBody", false);
      } else if (val == false) {
      }
    },

    showMenuOnIcon: function (val, oldVal) {
      if (val == true) {
        this.$store.commit("ClickBody", false);
      } else if (val == false) {
      }
    },
    active: function (val, oldVal) {
      if (val == true) {
        this.$store.commit("ClickBody", false);
      } else if (val == false) {
      }
    },

    toogleSignIn: function (newVal) {

    },
    media_match(newValue, oldValue) {
      if (newValue > 2) {
        this.toogleShowBackPage = true;
        this.show_header_search = false;
        this.show_search_action = true;
      }
    },
  },
  methods: {

    onClickTransparentBox() {
      this.ClickBody()
      this.showTransparentBox = false
    },
    disableScroll() {

      // Get the current page scroll position
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop

      let scrollLeft =
        window.pageXOffset || document.documentElement.scrollLeft

      // if any scroll is attempted,
      // set this to the previous value
      window.onscroll = function () {

        window.scrollTo(scrollLeft, scrollTop);
      };
    },

    enableScroll() {
      window.onscroll = function () { };
    },
    searchSubmit() {

      //this.$router.push({path: '/search', query:{q: this.busqueda}})



      var list = document.querySelectorAll("a.searchCategories, a.searchPerformers, a.searchEvents, a.searchCities ");
      var arrayChoose = Array.from(list)
      if (!arrayChoose || !arrayChoose[this.indexSelected]) return


      arrayChoose[this.indexSelected].classList.add('seleccionada')
      this.$router.push(arrayChoose[this.indexSelected].getAttribute("href"))







      //this.$router.push('/concerts')
    },
    menuMovilSwiper() {
      this.show_menu_status = false;

      document.getElementsByTagName("html")[0].style.overflowY = "";
      document.getElementsByTagName("body")[0].style.position = "";
      document.body.style.paddingRight = "0";

    },

    performerImageUrl(performer) {
      let fileName = `${performer.slug}/thumb@search.jpg`
      return `https://firebasestorage.googleapis.com/v0/b/peppertix-org.appspot.com/o/${(encodeURI(fileName)).replace("\/", "%2F")}?alt=media`;
    },
    searchFormSubmit() {
      this.$router.push(this.search_query);
    },
    headerKeyUp(event) {
      var list = document.querySelectorAll("a.searchCategories, a.searchPerformers, a.searchEvents, a.searchCities ");
      var arrayChoose = Array.from(list)
      if (!arrayChoose) return

      if (event.key == "ArrowDown") {
        if (arrayChoose.length > 0 && this.indexSelected + 1 <= arrayChoose.length - 1) {
          this.indexSelected++
        }
      } else if (event.key == "ArrowUp") {
        if (this.indexSelected - 1 >= 0) {
          this.indexSelected--
        }

      }

      if (event.key == "ArrowDown" || event.key == "ArrowUp") {
        for (let i = 0; i < arrayChoose.length; i++) {
          arrayChoose[i].classList.remove('seleccionada')
        }
        arrayChoose[this.indexSelected].classList.add('seleccionada')
      }











    },
    initIndexSelected() {
      this.indexSelected = 0
      var list = document.querySelectorAll("a.searchCategories, a.searchPerformers, a.searchEvents, a.searchCities ");
      var arrayChoose = Array.from(list)
      if (!arrayChoose || !arrayChoose[this.indexSelected]) return
      for (let i = 0; i < arrayChoose.length; i++) {
        arrayChoose[i].classList.remove('seleccionada')
      }
      arrayChoose[this.indexSelected].classList.add('seleccionada')

    },

    mouseLeaveCategory(category) {
      if (category) {
        category.seleccionada = false;
      }
    },
    mouseOverCity() { },
    mouseLeaveCity() { },
    mouseOverCategory(category) {
      if (category) {

        category.seleccionada = true;
        //this.busqueda = category.name;
        this.selectedMatched = {
          name: category.name,
          type: "category",
          slug: `/${category.slug}`
        };
      }
    },
    mouseOverPerformer(performer) {
      if (performer) {


        let artist = performer;
        let slug = artist.slug
          ? `/results/${artist.slug}`
          : `/results/${artist.name}`;
        this.selectedMatched = {
          type: "performer",
          name: artist.name,
          slug: slug
        };
      }
    },





    closeInviteFriends() {
      this.toogleInviteFriends = false;
    },
    clickInvitation(invitation) {
      let params = {
        inviterId: invitation.inviterId,
        eventId: invitation.eventId
      };

      invitationsApi.getInvitation(params).then(invitationRes => {
        this.invitationSelected = invitationRes;
        this.performer = this.invitationSelected.performer;
        this.toogleInviteFriends = true;
        //this.performer =  ,
        /////invitationSelected: {},
      });
    },
    clickViewNotifications() {
      this.toogleInvitation = !this.toogleInvitation;
    },
    ClickBody() {
      this.$store.commit("ClickBody", true);
      scroll.blockScroll(false)
      //this.enableScroll()


    },
    upatePerformer(performer) {
      let performer_id = performer.performer_id;
      this.$store
        .dispatch("performers/actionUpdatePerformerById", {
          performer_id
        })
        .then(response => {
          performer.imageUrl = response.data.imageUrl;
        });
    },
    async searchClick() {

      if (this.busqueda.length === 0) {


        let [musicCategories, cities] = await Promise.all([
          categoriesApi.getCategoriesByCategoriesId("[1,54,68,73]"),
          citiesApi.getCitiesBySlugs(
            `["san-francisco-ca","new-york-ny","las-vegas-nv","anaheim-ca"]`
          )
        ]);


        this.cities = cities;


        musicCategories = musicCategories.data;

        for (let i = 0; i < musicCategories.length; i++) {
          musicCategories[i].seleccionada = false;
        }

        this.musicCategories = musicCategories;


        let params = {
          category_id: 54,
          category_tree: true,
          //only_with_upcoming_events: true,
          order_by: "performers.popularity_score DESC",
          page: 1,
          per_page: 24
        };

        let [performersTevo] = await Promise.all([
          //performersApi.getPerformers(params)
          performersApi.getMorePopularPerformers()
        ]);

        //let performers = performersTevo.data.performers;
        let performers = performersTevo;

        for (let i = 0; i < performers.length; i++) {
          performers[i].id = performers[i].performer_id;
          performers[i].seleccionada = false;
        }
        this.searchedArtists = performers;
        //this.setImagesPerformers(performers);
        /**
         *
         *
         */
      } else if (this.busqueda.length > 0) {

      }

      this.initIndexSelected()
    },

    async setImagesPerformers(performers = []) {
      return new Promise(async (resolve, reject) => {
        let matriz_ids = "[";

        for (let i = 0; i < performers.length; i++) {
          if (i < performers.length - 1) {
            matriz_ids += performers[i].id + ",";
          } else if (i == performers.length - 1) {
            matriz_ids += performers[i].id;
          }
        }
        matriz_ids += "]";


        let [performersBD] = await Promise.all([
          performersApi.getArtistsByPerformersId(matriz_ids)
        ]);



        for (let k = 0; k < performers.length; k++) {
          const index = performersBD.findIndex(
            item => item.performer_id == performers[k].id
          );


          performers[k].imageUrl =
            "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";

          if (index >= 0) {
            performers[k].imageUrl = performersBD[index].imageUrl;
          } else {
          }
        }

        this.searchedArtists = performers;
        resolve(this.searchedArtists);

        for (let k = 0; k < performers.length; k++) {
          const index = performersBD.findIndex(
            item => item.performer_id == performers[k].id
          );


          performers[k].imageUrl =
            "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";

          if (index >= 0) {
            performers[k].imageUrl = performersBD[index].imageUrl;
          } else {

            let performer_id = performers[k].id;

            performersApi
              .updatePerformerById(performer_id)
              .then(performerUpdated => {
                performers[k].imageUrl = performerUpdated.data.imageUrl;
              });
          }
        }
      });
    },
    navigateToHome() {
      let ruta = {
        path: "/"
      };
      this.$router.push(ruta);
    },
    saveArtist(name) {

    },
    mouseOver: function () {
      this.active = true;
    },
    toogleActiveMenuClick() {
      this.active = !this.active
    },
    showmenu: function () {

      if (this.show_menu_status == false) {
        this.show_menu_status = true;


        //document.body.style.paddingRight = "17px";

      } else {
        this.show_menu_status = false;



        document.body.style.paddingRight = "0";
      }
    },

    searchback: function () {
      this.toogleShowBackPage = true;
      this.show_header_search = false;
      this.show_search_action = true;

      this.showTransparentBox = false


      document.getElementsByTagName("html")[0].style.overflowY = "";
      document.getElementsByTagName("body")[0].style.position = ""
      document.body.style.paddingRight = "0";


      this.$store.commit("ClickBody", true);
    },

    showsearch: function () {

      this.toogleShowBackPage = false;
      this.show_header_search = true;
      this.show_search_action = false;






      this.$nextTick(() => {

        if (document.getElementById("busqueda")) {


          const searchInput = document.getElementById("busqueda")
          //searchInput.style.display = 'block';
          // Adding a small delay to ensure rendering is complete
          setTimeout(() => {
            searchInput.focus();
            searchInput.click();
            //searchInput.setSelectionRange(0, 0);
            //this.simulateTap(searchInput)
            //console.log('focus searchInput')
          }, 100);
        }


      });



    },

    simulateTap(element) {
      // Create a new touch event
      var touchEvent = new Event('touchstart', { bubbles: true, cancelable: true });

      // Dispatch the touch event
      element.dispatchEvent(touchEvent);

      // Focus the input

      element.focus();
      element.setSelectionRange(0, 0);
    },


    mouseLeaveSearch: function () {

    },
    mouseLeave: function () {
      this.active = false;
    },
    onLogout() {
      this.$store.dispatch("user/logout");

      this.active = false;


      if (this.$route.path.includes('user')
        //|| this.$route.path.includes('finish_purchase' )
        //|| this.$route.path.includes('checkout' )
      ) {
        this.$router.push(`/`)
      } else if (this.$route.path.includes('/subscription/checkout')) {
        this.$router.push(`/subscription`)
      } else if (this.$route.path.includes('/subscription/checkout')) {

      }


      this.$store.commit("ClickBody", true)
    },


    closeSignIn() {
      this.$store.commit("toogleSignIn", false)
      this.$store.commit("showPromoToast", true)
      this.$store.commit("ClickBody", true)
    },
    openSignIn() {

      this.$store.commit("signinform/signUpModePromo", false)
      this.$store.commit("signinform/toogleSignUp", false)

      this.$store.commit("signinform/recoveryToogle", false)
      this.$store.commit("signinform/recoverySendEmail", false)


      this.$store.commit("userPromoAdded", false);
      this.$store.commit("showPromoToast", false)
      this.$store.commit("toogleSignIn", true)
      this.$store.commit("ClickBody", false)
      scroll.blockScroll(true)

    },

    errorArtistImage(artist, index) {
      artist.imageUrl =
        "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_concert.jpg?alt=media&token=08e0b469-5247-4f84-9ba6-9eb36ccee2e4";
    },
    imageLoadOnError() {
      this.$store.commit("user/storeImgSrc", "/img/home/profile.svg");
    },
    onClickMenuOnIcon() {
      if (this.showMenuOnIcon) {
        this.showMenuOnIcon = false;
        this.showTransparentBox = false;


        this.$store.commit("openCategoriesMenu", false)
        this.$store.commit("openCategoryMenu", true)



        document.getElementsByTagName("html")[0].style.overflowY = "";

      } else {


        this.active = false

        this.showMenuOnIcon = true;
        this.showTransparentBox = true;


        document.getElementsByTagName("html")[0].style.overflowY = "hidden";


        this.$store.commit("openCategoriesMenu", true)
        this.$store.commit("openCategoryMenu", false)
      }

    },
    onClickMenuDropdownLevelOne(e) {
      document.getElementById('dropdown_menu_second_level').scrollTo(0, 0)
      const allCategories = document.querySelectorAll(".category");
      const TitleSubCategory = document.querySelectorAll(".goback_first_level_action a")[0];
      const allSubCategories = document.querySelectorAll(".subcategory");
      const menuThirdLevel = document.querySelectorAll(".dropdown_menu_third_level")[0];
      const subSubcategories = document.querySelectorAll(".subsubcategory");
      TitleSubCategory.innerHTML = e.target.innerText.toLowerCase();
      for (let index = 0; index < allCategories.length; index++) {
        const element = allCategories[index];
        element.classList.remove("active");
      }
      e.target.parentElement.classList.add("active");
      for (let index = 0; index < allSubCategories.length; index++) {
        const element = allSubCategories[index];
        element.classList.remove("active");
        const allSubCategoryItems = element.querySelectorAll('li');
        for (let indexSubcategoryItem = 0; indexSubcategoryItem < allSubCategoryItems.length; indexSubcategoryItem++) {
          const subcategoryItem = allSubCategoryItems[indexSubcategoryItem];
          subcategoryItem.classList.remove("active");
        }
      }
      const currentSubCategory = document.getElementById("sub" + e.target.parentElement.id);
      if (!currentSubCategory) return

      currentSubCategory.classList.add("active");
      menuThirdLevel.classList.remove("active");
      for (let index = 0; index < subSubcategories.length; index++) {
        const element = subSubcategories[index];
        element.classList.remove("active");
      }
    },
    onClickMenuDropdownLevelTwo(e) {
      document.getElementById('dropdown_menu_third_level').scrollTo(0, 0)
      const allSubCategoryItems = e.target.parentElement.parentElement.querySelectorAll('li');
      const allSubSubCategories = document.querySelectorAll(".subsubcategory");
      const menuFirstLevel = document.querySelectorAll(".dropdown_menu_first_level")[0];
      const menuSecondLevel = document.querySelectorAll(".dropdown_menu_second_level")[0];
      menuFirstLevel.classList.add("is_not_first_level");
      menuSecondLevel.classList.add("is_first_level");
      for (let index = 0; index < allSubCategoryItems.length; index++) {
        const element = allSubCategoryItems[index];
        element.classList.remove("active");
      }

      e.target.parentElement.classList.add("active");
      for (let index = 0; index < allSubSubCategories.length; index++) {
        const element = allSubSubCategories[index];
        element.classList.remove("active");
      }

      const currentSubSubCategory = document.getElementById(e.target.parentElement.id + "_categories");
      console.log(e.target.parentElement.id + "_categories");
      if (currentSubSubCategory) {
        currentSubSubCategory.classList.add("active"); //activa el tercer nivel
        currentSubSubCategory.parentElement.classList.add("active");
      } else {
        console.log('---');
      }
    },
    onClickMenuDropdownLevelThree(e) {
      const allSubCategoryItems = e.target.parentElement.parentElement.querySelectorAll('li');
      for (let index = 0; index < allSubCategoryItems.length; index++) {
        const element = allSubCategoryItems[index];
        element.classList.remove("active");
      }
      e.target.parentElement.classList.add("active");
    },
    goBackMenuFirstLevel(e) {
      const menuFirstLevel = document.querySelectorAll(".dropdown_menu_first_level")[0];
      const menuSecondLevel = document.querySelectorAll(".dropdown_menu_second_level")[0];
      const menuThirdLevel = document.querySelectorAll(".dropdown_menu_third_level")[0];
      const allSubCategories = document.querySelectorAll(".subcategory");
      const allSubSubCategories = document.querySelectorAll(".subsubcategory");
      menuFirstLevel.classList.remove("is_not_first_level");
      menuSecondLevel.classList.remove("is_first_level");
      menuThirdLevel.classList.remove("active");
      for (let index = 0; index < allSubCategories.length; index++) {
        const element = allSubCategories[index];
        const allSubCategoryItems = element.querySelectorAll('li');
        for (let indexSubcategoryItem = 0; indexSubcategoryItem < allSubCategoryItems.length; indexSubcategoryItem++) {
          const subcategoryItem = allSubCategoryItems[indexSubcategoryItem];
          subcategoryItem.classList.remove("active");
        }
      }
      for (let index = 0; index < allSubSubCategories.length; index++) {
        const element = allSubSubCategories[index];
        element.classList.remove("active");
      }
    },
    closeMenuOnIcon(e) {

      this.showMenuOnIcon = false;
      this.showTransparentBox = false;


      this.$store.commit("openCategoriesMenu", false)
      this.$store.commit("openCategoryMenu", true)
    }
  }
};
