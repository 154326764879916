let concerts = [
  {
    "name": "Classical",
    "id": "67",
    "slug": "classical"
  },{
    "name": "Alternative & Indie",
    "id": "58",
    "slug": "alternative-indie"
  }, {
    "name": "Latin",
    "id": "85",
    "slug": "latin"
  },{
    "name": "Cabaret",
    "id": "83",
    "slug": "cabaret"
  },{
    "name": "Festivals",
    "id": "63",
    "slug": "festivals"
  },{
    "name": "Miscellaneous",
    "id": "87",
    "slug": "miscellaneous"
  },{
    "name": "Rock & Pop",
    "id": "57",
    "slug": "rock-pop"
  },{
    "name": "Hard Rock Metal",
    "id": "64",
    "slug": "hard-rock-metal"
  },{
    "name": "Country & Folk",
    "id": "61",
    "slug": "country-folk"
  },{
    "name": "New Age & Spiritual",
    "id": "86",
    "slug": "new-age-spiritual"
  },{
    "name": "World Music",
    "id": "65",
    "slug": "world-music"
  },{
    "name": "R&B Urban Soul",
    "id": "60",
    "slug": "r-b-urban-soul"
  },{
    "name": "Rap & Hip-Hop",
    "id": "59",
    "slug": "rap-hip-hop"
  },{
    "name": "Alternative Rock",
    "id": "82",
    "slug": "alternative-rock"
  },{
    "name": "Jazz & Blues",
    "id": "62",
    "slug": "jazz-blues"
  },{
    "name": "Dance & Electronic",
    "id": "84",
    "slug": "dance-electronic"
  }, 
];

let sports = [
  {
    
    id :"7",
    name: 'Basketball',
    slug: 'basketball',
  },
  {
    id: "19",
    name: 'Football',
    slug: 'football',
  },

  {
    id :"2",
    name: 'Baseball',
    slug: 'baseball',
  },

  {
    id :"44",
    name: 'Fighting',
    slug: 'fighting',
  },

  {
    id :"34",
    name: 'Soccer',
    slug: 'soccer',
  },
  {
    id :"15",
    name: 'Hockey',
    slug: 'hockey',
  },

  {
    id :"25",
    name: 'Tennis',
    slug: 'tennis',
    url: '/sports/tennis',
    noChildren: true
  },

  {
    
    id :"27",
    name: 'Auto Racing',
    slug: 'auto-racing',
    url: '/sports/auto-racing',
    noChildren: true
  },
  
  /*
  {
    name: 'Volleyball',
    slug: 'volleyball',
  },

  {
    name: 'Softball',
    slug: 'softball',
  },
  {
    name: 'Skiing',
    slug: 'skiing',
  },
  {
    name: 'Rugby',
    slug: 'rugby',
  },
  {
    name: 'Rodeo',
    slug: 'rodeo',
  },
  {
    name: 'Motorsports',
    slug: 'motorsports',
  },
  {
    name: 'Miscellaneous Sports',
    slug: 'miscellaneous-sports',
  },
  {
    name: 'Horse Racing',
    slug: 'horse-racing',
  },
  {
    name: 'Lacrosse',
    slug: 'lacrosse',
  },

  {
    name: 'International Games',
    slug: 'international-games',
  },
  {
    name: 'Golf',
    slug: 'golf',
  },

  {
    name: 'Extreme Sports',
    slug: 'extreme-sports',
  },
  {
    name: 'Auto Racing',
    slug: 'auto-racing',
  },
  {
    name: 'Cycling',
    slug: 'cycling',
  },
  {
    name: 'Cricket',
    slug: 'cricket',
  },*/
];

let special_events = [
  {
    id : "214",
    name: 'Conventions',
    slug: 'conventions',
  },
  {
    
    id: "213", 
    name: 'Miscellaneous Special Events',
    slug: 'miscellaneous-events',
  },
  {
    
    id : "75",
    name: 'TV Shows',
    slug: 'tv-shows',
  },
  {
    
    id : "140",
    name: 'Museums',
    slug: 'museums',
  },
  {
    id : "77", 
    name: 'Exclusive Parties',
    slug: 'exclusive-parties',
  },
  /*{
    name: 'Fundraisers',
    slug: 'fundraisers',
  },*/
  {
    id: "142", 
    name: 'Attractions',
    slug: 'attractions',
  },
  {
    
    id : "74",
    name: 'Award Shows',
    slug: 'award-shows',
  },
];

let theatre = [
  {
    
    id: "70", 
    name: 'Plays',
    slug: 'plays',
  },
  {
    
    id: "71",
    name: 'Opera',
    slug: 'opera',
  },
  {
    
    id: "69",
    name: 'Musicals',
    slug: 'musicals',
  },
  {
    
    id: "138",
    name: 'Movies',
    slug: 'movies',
  },
  {
    
    id : "72",
    name: 'Ballet Dance',
    slug: 'ballet-dance',
  },
  {
    
    id :  "212",
    name: 'Entertainment Shows',
    slug: 'entertainment-shows',
  },
];


let basketBallCategories = [{
  
  id: "8",
  "name": "NBA",
  "slug": "nba"
},{
  
  id: "12",
 
  "name": "FIBA World Championship",
  "slug": "fiba-world-championship"
},{
 
  id : "13",
  "name": "Euroleague Basketball",
  "slug": "euroleague-basketball"
},{
 
  id: "122",
  "name": "NBA D-League",
  "slug": "nba-d-league"
},{
 
  id: "9",
  "name": "WNBA",
  "slug": "wnba"
},{
 
  id : "14",
  "name": "International Friendlies",
  "slug": "international-friendlies"
},{
 
  id:  "10",
  "name": "NCAA M",
  "slug": "ncaa-men-basketball"
},{
 
  id : "129",
  "name": "High School Basketball",
  "slug": "high-school"
},{
 
  id: "11",
  "name": "NCAA W",
  "slug": "ncaa-women-basketball"
}]

let footballCategories = [{

  "name": "NFL",
  "slug": "nfl"
},{
 
  "name": "UFL",
  "slug": "ufl"
},{
 
  "name": "Legends Football League",
  "slug": "legends-football-league"
},{
 
  "name": "AFL",
  "slug": "afl"
},{
 
  "name": "AAF",
  "slug": "aaf"
},{
 
  "name": "NCAA Football",
  "slug": "ncaa-football"
},{
 
  "name": "CFL",
  "slug": "cfl"
},{
 
  "name": "NAL",
  "slug": "nal"
},{
 
  "name": "IFL",
  "slug": "ifl"
}]

let baseballCategories = [{
 
  "name": "World Baseball Classic",
  "slug": "world-baseball-classic"
},{
 
  "name": "MLB",
  "slug": "mlb"
},{
 
  "name": "NCAA Baseball",
  "slug": "ncaa"
},{
 
  "name": "Minor League Baseball",
  "slug": "minor-league"
},{
 
  "name": "Mexican League Baseball",
  "slug": "mexican-league"
}]


let fightingCategories = [{
  "name": "Boxing",
  "slug": "boxing"
},{
 
  "name": "Wrestling",
  "slug": "wrestling"
},{
 
  "name": "Mixed Martial Arts (MMA)",
  "slug": "mixed-martial-arts-mma"
}]

let soccerCategories =  [{
 
  "name": "Confederacao Brasileira de Futebol",
  "slug": "confederacao-brasileira-de-futebol"
},{
 
  "name": "World Cup",
  "slug": "world-cup"
},{
 
  "name": "Spanish Primera Division",
  "slug": "spanish-primera-division"
},{
 
  "name": "MLS",
  "slug": "mls-major-league-soccer"
},{
 
  "name": "Federacion Mexicana de Futbol",
  "slug": "federacion-mexicana-de-futbol"
},{
 
  "name": "Ligat HaAl",
  "slug": "ligat-haal"
},{
 
  "name": "Bundesliga",
  "slug": "bundesliga"
},{
 
  "name": "Italian Serie A",
  "slug": "italian-serie-a"
},{
 
  "name": "Scottish Premiere League",
  "slug": "scottish-premiere-league"
},{
 
  "name": "English Premiere League",
  "slug": "english-premiere-league"
},{
 
  "name": "NCAA Men Soccer",
  "slug": "ncaa-men-soccer"
},{
 
  "name": "NCAA Women Soccer",
  "slug": "ncaa-women-soccer"
}]

let hockeyCategories =  [{
 
  "name": "NHL",
  "slug": "nhl"
},{
 
  "name": "Hockey Junior",
  "slug": "hockey-junior"
},{
 
  "name": "AHL",
  "slug": "ahl"
},{
 
  "name": "NCAA Women Hockey",
  "slug": "ncaa-women-hockey"
},{
 
  "name": "NCAA Men Hockey",
  "slug": "ncaa-men-hockey"
},{
 
  "name": "ECHL",
  "slug": "echl"
},{
 
  "name": "International Hockey",
  "slug": "international-hockey"
}]

export default {
  concerts,
  sports,
  theatre,
  special_events,
  basketBallCategories,
  footballCategories,
  baseballCategories,
  fightingCategories,
  soccerCategories,
  hockeyCategories
};


 