export default function ({ route, redirect }) {
    // const restrictedPaths = [
    //   '/app/events',
    //   '/app/signup',
    //   '/app/signin',
    //   '/app/search',
    //   '/app/faves',
    //   '/app/events-notifications',
    //   '/app/event-details',
    //   '/app/settings',
    //   '/app/no-faves',
    //   '/app/reset-pass',
    //   '/app/user/faves',
    //   '/app/check-your-email'
    // ];
  
    // if (restrictedPaths.includes(route.path)) {
    //   return redirect('/app'); //  
    // }
    if (route.path.startsWith('/app/')) {
        // Redirect to the desired URL, for example to the home page
        redirect('/app'); // Or any other URL you want to redirect to
      }

  }