import Vue from "vue";

import FBSignInButton from "vue-facebook-signin-button";
Vue.use(FBSignInButton);

import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

import { VueMaskDirective } from 'v-mask'
Vue.directive('mask', VueMaskDirective);

/*import VueSocialSharing from 'vue-social-sharing'
 
Vue.use(VueSocialSharing);*/


/*var SocialSharing = require('vue-social-sharing');

Vue.use(SocialSharing);*/
