const isValidHomeUrl = (string) => {
    // List of valid language codes
    const validCodes = ['es', 'en', 'fr', 'de', 'it', 'pt', 'nl', 'ru', 'zh'];

    // Create the regular expression pattern
    const pattern = new RegExp('^/(' + validCodes.join('|') + ')?$');
    return pattern.test(string);
}


export default {
    isValidHomeUrl
};
