import api from "@/api/api";

export default ({
  req,
  store
}) => {
  if (process.server) {
    api.getLocationInfo().then((res) => {
      let ip = res.data.ip
      let lat = res.data.latitude
      let lon = res.data.longitude
      if (lat && lon) {
       
        //store.commit('SET_IP', ip)
      }
    })
    //store.commit('SET_IP', ip)
  }
  if (process.client) {
    //localStorage.setItem('ip', store.getters.ip)
  }
}
