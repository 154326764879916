/* eslint-disable */

import globalAxios from "axios";
import configVars from "../config/config_vars";
const appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";

function generateSortFn(prop, reverse) {
  return function (a, b) {
    if (a[prop] < b[prop]) return reverse ? 1 : -1;
    if (a[prop] > b[prop]) return reverse ? -1 : 1;
    return 0;
  };
}

export const state = () => ({});

export const getters = {};

export const mutations = {};

export const actions = {
  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchCities
   * ============================
   */
  searchCities({
    commit,
    dispatch
  }, params) {
    let searchCitiesQuery = `${appBaseUrl}db/cities/?${querystring.stringify(
      params
    )}`;
     
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchCitiesQuery}`)
        .then(response => {
        
          resolve(response);


        })
        .catch(error => {
       
          reject(`error-searchCities ${error}`);
        });
    });
  }
};
