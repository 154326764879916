
import logger from "@/util/logger";
import scroll from "@/util/scroll";
import Cookies from "js-cookie";
import { mapMutations, mapGetters } from "vuex";
import spotifySignin from '@/mixins/spotify_signin'
import facebookSignin from '@/mixins/facebook_signin'
import googleSignin from '@/mixins/google_signin'
export default {
  //middleware: "log",
  computed: {
    ...mapGetters({
      isAutenticated: "user/isAutenticated",
      activePromoCode: "checkout/activePromoCode",
      tooglePromoRegister: "tooglePromoRegister",
    }),
  },
  mixins: [
    spotifySignin,
    facebookSignin,
    googleSignin,

  ],
  data() {
    return {
      displaySidenav: false,
    };
  },
  //middleware: ["check-auth"],
  created() { },
  mounted() {
    this.$nextTick(() => {
      logger.logger();

      scroll.blockScroll(false);

      this.$store.dispatch("user/tryAutoSignin");

      this.handleFacebookResponse()
      //this.handleGoogleResponse()
      this.handleSpotifyAuth()

      let body = {};
      if (this.$route.query.promocode) {
        body.code = this.$route.query.promocode;
        body.promoCodeFromUrl = true;
      }
      if (body.code)
        this.$store.dispatch("checkout/verifyPromoCode", body).then((res) => {
          this.$store.commit("showPromoToast", true);
        });


      if (
        this.$route.query.signup == "true"
      ) {
        this.$store.commit("signinform/signUpModePromo", false);
        this.$store.commit("signinform/toogleSignUp", true);
        this.$store.commit("toogleSignIn", true);
        this.$store.commit("showPromoToast", false);
      }

      if (this.$route.query.msn == "true") {
        this.$store.commit("loginOnPageReload", true);
      }
      if (

        this.$route.query.getpromo == "true"
      ) {
        this.$store.commit("signinform/signUpModePromo", true);
        this.$store.commit("signinform/toogleSignUp", true);
        this.$store.commit("toogleSignIn", true);
        this.$store.commit("showPromoToast", false);
      }

    });
  },

  watch: {},

  methods: {},

  destroyed: function () {
    this.$store.commit("ClickBody", true);
  },
};
