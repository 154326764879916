/*import Vue from 'vue';
import {
  Carousel,
  Slide
} from 'vue-carousel'
Vue.component('carousel', Carousel);
Vue.component('slide', Slide);*/


import Vue from 'vue'
import VueCarousel from 'vue-carousel'

Vue.use(VueCarousel)

