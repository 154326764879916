// SpotifyMixin.js
import { mapMutations, mapGetters } from "vuex";
import spotify from "@/api/spotify";
import performersRequests from '@/api/performers'
export default {
    computed: {
        ...mapGetters({
            user: "user/user",
            isAuthenticated: "user/isAuthenticated",
            spotifyToken: "user/spotifyToken",
        }),
    },
    data() {
        return {
            spotifyUser: {
                firstName: "",
                lastName: "",
                email: "",
                password: "",
                spotifyId: "",
                images: [],
                imgSrc: "",
                senderId: "",
                tipo: "spotify",
            },
            senderId: null,
          
            performerToSave: null,
        };
    },
    methods: {
        handleSpotifyAuth() {
            const query = this.$route.query;
            const accessToken = query.access_token;
            const signin_with = query.signin_with;

            this.performerToSave = query.performerToSave

             
            if (accessToken && signin_with === 'spotify') {
                this.$store.commit("user/spotifyToken", accessToken)
                console.log(this.spotifyToken)
                this.spotifyLogin(accessToken);
                const url = new URL(window.location.href);
                url.searchParams.delete('access_token');
                url.searchParams.delete('signin_with');
                url.searchParams.delete('performerToSave');
                url.searchParams.delete('v');
                window.history.replaceState({}, document.title, url);
            }
        },

        async spotifyLogin(access_token) {
            let userData = null;
            console.log('console.log(access_token)')
            console.log(access_token)
         
            try {
                userData = await spotify.getMe(access_token);
            } catch (error) {
                console.log('error')
                console.error(error)
            }

            if (userData) {
                this.spotifyUser = userData.data.user;
                let dataForSave = {
                    firstName: this.spotifyUser.display_name,
                    lastName: "",
                    email: this.spotifyUser.email,
                    password: this.spotifyUser.email,
                    spotifyId: this.spotifyUser.id,
                    imgSrc: this.spotifyUser.images[0]?.url,
                    recenty_played: [],
                    following: [],
                    senderId: this.senderId,
                    tipo: "spotify",
                };

                console.log('dataForSave')
                console.log(dataForSave)
                await this.spotifyLoginSecondStep(dataForSave);
            }
        },



        async spotifyLoginSecondStep(dataForSave) {
            try {
                let userSignUpData = await this.$store.dispatch(
                    "user/signup",
                    dataForSave
                );
            } catch (error) { }

            if (!this.user || this.isAutenticated == false) {
                return;
            }
            if (this.performerToSave) {

                const performersResponse = await performersRequests.getTevoPerformerById(this.performerToSave)

                let performerForSave = {
                    performer_id: performersResponse.id,
                    name: performersResponse.name,
                    images: [],
                    popularity_score: performersResponse.popularity_score,
                    category_id: performersResponse.category.id, // this.performer.category.id,
                    category_name: performersResponse.category.name, //this.performer.category.name,
                    activado: true,
                };
                let datos = {
                    performers: [performerForSave],
                    category_id: 54, //categoría para los conciertos
                };

                await this.$store.dispatch("user/setPerformersToSave", datos);

                try {
                    let setPerformerResponse = await this.$store.dispatch(
                        "user/setUserPerformers",
                        datos
                    );
                } catch (error) { }


                this.$store.dispatch("user/setIsFavorite", true);
                this.$store.commit("storeFollow", true);


                try{
                    let response = 
                    await spotify.meFollowing(performersResponse.name, this.spotifyToken )
                 
                  }catch(error){
                  
          
                  }


                  if (this.user._id) {
                    try {
                        let spotifySaveMyArtistsResponse = await spotify.saveMyArtists(
                            this.user._id,
                            this.spotifyToken
                        );
                    } catch (error) { }
                } else {
                }

            }

           

            await this.$store.dispatch("user/updateUser");
        },

    },
};
