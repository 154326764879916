export default function changeOpacityMixin() {
    return {
        created(){
            //this.changeOpacity('0');
        },
        mounted() {
            this.changeOpacity('1');
        },
        methods: {
            changeOpacity(opacity) {

                document.fonts.ready.then(function () {
                    // if(!document.fonts.check("12px Material-Icons")) {
                        
                    // }
                    console.log('changing opacity...')
                    const icons = document.querySelectorAll('.material-icons');
                    icons.forEach(function (icon) {
                      icon.style.opacity = opacity;
                    });
                });


               
                
            }
        }
    }

};
