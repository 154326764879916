import Vue from "vue";
import pushIfNew from "@/util/push_if_new"
export const state = () => ({
  homeCards: [],
  locality: "",
  queryBase: {}
});


export const getters = {
  homeCards(state) {
    return state.homeCards;
  },
  locality(state) {
    return state.locality;
  },
  queryBase(state) {
    return state.queryBase;
  },
};


export const mutations = {
  locality(state, locality){
    state.locality = locality;
  },
  homeCards(state, homeCards){
    state.homeCards = homeCards
  },


  queryBase(state, queryBase){
    state.queryBase = queryBase
  },


  addNewHomeCard(state, newHomeCard) {
    let index = state.homeCards.findIndex(item => item.id == newHomeCard.id)
    if(index < 0 ){
      state.homeCards =  [newHomeCard, ...state.homeCards]   
    }
      
  },
  addMoreCards(state, newCards){
    let newCardsState = [...state.homeCards]

    newCards.map((event)=>{
      pushIfNew.pushIfNew(newCardsState, event )
    })

    state.homeCards = newCardsState

  },

  updateHomeCard(state, newHomeCard){

    let index = state.homeCards.findIndex(item =>  item.id == newHomeCard.id )
    if(index >= 0){
      state.homeCards[index] = newHomeCard;
      Vue.set(state, 'homeCards', [...state.homeCards]); //esta linea es necesaria
    }else{
    
    }
  },
  
  deleteAllFavorites(state){
    console.log('deleteAllFavorites')
    state.homeCards = state.homeCards.map((item) =>{ item.isFavorite = false;  return item;} )
    Vue.set(state, 'homeCards', [...state.homeCards]);
  },

  updateHomeCardByIndex(state, data){

    let index = data.index;
    let isFavorite = data.isFavorite
   
    if(index >= 0){
      state.homeCards[index].isFavorite  =  isFavorite;
    }
  },

    

  updateIsFavorite(state , data ){

    let index = state.homeCards.findIndex(item => item.performances.performer.id == data.performerId)
   
    if(index >= 0){
      let newHomeCard  = state.homeCards[index];
      newHomeCard.isFavorite = data.isFavorite;
   

      state.homeCards[index] = newHomeCard;
   
      
      Vue.set(state, 'homeCards', [...state.homeCards]);

    }

  
  },
    

    
    
};


