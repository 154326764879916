import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";




const getMe = (acces_token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}sp/getMe/${acces_token}`;
  return instance.get(`${url}`)
}


const meFollowing = (name, acces_token) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}sp/meFollowing/${name}/${acces_token}`
  return instance.put(`${url}`)
}



const saveMyArtists = (_id, acces_token, body) => {
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.timeout = 9000;
  let url = `${appBaseUrl}sp/saveMyArtists/${_id}/${acces_token}`
  return instance.post(`${url}`, body)
}


const codeSignin = async (code) => {
  const credentials = {
    clientId: process.env.SP_CLIENT_ID,   // '515beaa6ac52472a81dcad5ebb06d45e',
    clientSecret: process.env.SP_CLIENT_SECRET,  //'31568a66e77f4dacac2cb5075fffc6d0',
  };
  const redirectUri = `http://localhost:3070/?signin=spotify_auth&v=true`//`http://localhost:3070/spotify_auth`; // Cambia según sea necesario

  const authorizationCode = code; // Obtiene el código de autorización de la URL


  if (!authorizationCode) {
    return { error: 'No se encontró el código de autorización.' };
  }

  try {
    const response = await fetch('https://accounts.spotify.com/api/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Basic ' + Buffer.from(`${credentials.clientId}:${credentials.clientSecret}`).toString('base64'),
      },
      body: new URLSearchParams({
        grant_type: 'authorization_code',
        code: authorizationCode,
        redirect_uri: redirectUri,
      }),
    });

    if (!response.ok) {
      throw new Error('Error en la solicitud de token: ' + response.statusText);
    }

    const data = await response.json();
    const access_token = data.access_token;

    // Lógica de spotifyLogin movida aquí
    let userData = null;
    try {
      userData = await getMe(access_token);
    } catch (error) {
      return { error: 'Error obteniendo datos del usuario de Spotify.' };
    }

    // Preparar datos para guardar el usuario


    const spotifyUser = userData.data.user;

    console.log('spotifyUser')
    console.log(spotifyUser)
    const dataForSave = {
      firstName: spotifyUser.display_name,
      lastName: "",
      email: spotifyUser.email,
      password: spotifyUser.email,
      spotifyId: spotifyUser.id,
      imgSrc: spotifyUser.images[0].url,
      recenty_played: [],
      following: [],
      senderId: '',  // Asegúrate de tener este valor
      tipo: "spotify",
    };
    console.log('dataForSave')
    console.log(dataForSave)
    return dataForSave

    //return { access_token }; // Retorna el token de acceso
  } catch (error) {
    return { error: error.message };
  }
}

const loginWithSpotifyRedirect = (context, state) => {
  const authPathComplement = '/auth/spotify';
  const APP_FRONT_URL = context.$config.APP_FRONT_URL
  const redirectUri = `${APP_FRONT_URL}${authPathComplement}`
  const clientId = context.$config.SP_CLIENT_ID
  const scopes = 'user-follow-modify streaming user-read-private user-read-email user-read-recently-played user-follow-read user-top-read user-read-currently-playing'; // Los permisos que deseas solicitar
  const spotifyAuthUrl = `https://accounts.spotify.com/authorize?response_type=code&client_id=${clientId}&scope=${encodeURIComponent(scopes)}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${state}`;
  window.location.href = spotifyAuthUrl;


}
export default {
  codeSignin,
  getMe,
  meFollowing,
  saveMyArtists,
  loginWithSpotifyRedirect
}
