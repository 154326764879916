import Vue from 'vue';
import { MglMap, MglGeolocateControl, MglMarker, MglFullscreenControl, MglNavigationControl  } from 'vue-mapbox';
import Mapbox from 'mapbox-gl';

Vue.component('MglMap', MglMap);
Vue.component('MglMarker', MglMarker);
Vue.component('MglGeolocateControl', MglGeolocateControl);
Vue.component('MglFullscreenControl', MglFullscreenControl);
Vue.component('MglNavigationControl', MglNavigationControl);

Vue.prototype.$mapbox = Mapbox;