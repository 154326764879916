
import { mapGetters } from "vuex";
import { fromEvent } from "rxjs";
import {
  filter,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
} from "rxjs/operators";

import invitationsApi from "@/api/invitations";
import configVars from "@/config/config_vars";

import performersApi from "@/api/performers";
import categoriesApi from "@/api/categories";
import citiesApi from "@/api/cities";
import eventsApi from "@/api/events";
import eventsEvenbrite from "@/api/eventbrite";


import momentFunctions from "@/util/momentFunctions";
import windowScrollPosition from '@/mixins/window-scroll-position'
import eventsFunctions from "@/util/events_functions";
let appFrontUrl = configVars.appFrontUrl;

export default {
  mixins: [windowScrollPosition('position')],
  computed: {
    ...mapGetters({
      media_match: "media_match",
      isClickBody: "isClickBody",
      openCategoriesMenu: "openCategoriesMenu",
      openMobileSearch: "openMobileSearch",

    }),
    q() {
      return this.$route.query.q || "";
    },
    isSearchPage() {

      return this.$route.path.includes('/search')
        && this.$route.query.q
        && this.$route.query.q?.length > 0


    },

  },
  props: {
    theme: {
      type: String,
      default: "banner", /* banner |  top */
    },
  },
  data() {
    return {
      searchInput: '',
      searchInputChange: false,
      showResultList: true,
      showSearchForm: true,
      showInner: false,
      drawer: false,
      search_query: "",
      blockearSearch: false,
      selectedMatched: {},
      busquedaMovil: "",
      appFrontUrl: process.env.APP_FRONT_URL,
      busqueda: "",
      performer: {},
      invitationSelected: {},
      toogleShowBackPage: true,
      toogleInvitation: false,
      toogleSignIn: false,
      toogleInviteFriends: false,
      toogleSignUp: false,
      magic_flag: false,
      show_search_action: true,
      show_header_search: false,

      active: false,
      show_menu_status: false,
      musicCategories: [],
      searchedArtists: [],
      searchedEvents: [],
      eventBriteEvents: [],
      cities: [],
      indexCategories: -1,
      indexPerformers: -1,
      indexEvents: -1,
      overCategories: true,
      overPerformers: false,
      loading: false,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.$store.commit("magic_flag", false);
      const inputBusqueda = this.$refs.busquedaRef; // document.querySelector("#busqueda");


      fromEvent(inputBusqueda, "keyup")
        .pipe(
          map((e) => e.target.value),
          filter((text) => text.length > 0),
          debounceTime(300),
          distinctUntilChanged(),
          //switchMap(this.searching),
          switchMap(async () => {
            //console.log(this.$route.path.includes('/search')) 
            this.searchingAction()


          })
        )
        .subscribe(async () => {




        });
      if (
        this.media_match === 0 ||
        this.media_match === 1
      ) {
        this.showSearchForm = false
      } else {
        this.showSearchForm = true
      }
      if (this.isSearchPage) {

        //this.$refs.busquedaRef.value =  this.$route.query.q
        this.searchInput = this.$route.query.q

        //this.searchClick()

        if (this.media_match <= 2 && this.searchInput.length > 0) {
          //console.log(`this.magic_flag ${this.magic_flag}` )
          //this.magic_flag = false
        }

        if (this.media_match <= 2) {
          //this.$store.commit("openMobileSearch", true);
        }
        //
      }
    });
  },

  watch: {
    q(newVal) {

    },
    isSearchPage: function (val, oldVal) {
      if (val == true) {


      }
    },
    searchInput() {
      console.log('searchInput')
      this.searchInputChange = true;
    },

    isClickBody: function (val, oldVal) {
      if (val == true) {
        this.magic_flag = false;
      }
    },
    openCategoriesMenu: function (val, oldVal) {
      if (val == true) {
        this.magic_flag = false
        //this.$store.commit("openCategoryMenu", true);
      }
    },
    magic_flag: function (val, oldVal) {
      console.log(`magic_flag::: ${this.magic_flag}`)
      if (val == true) {

        let containerGeneral = document.querySelectorAll(".container_general")[0];



        /*let containerSearchInBanner = document.getElementsByClassName("banner-search-cont");
        containerSearchInBanner[0].style.height = containerGeneral.clientHeight + 'px'; */

        const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
        console.log(this.position)
        let newHeight = vh + this.position[1] - 362

        if (this.$route.path == '/') {
          const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
          console.log(this.position)
          newHeight = vh + this.position[1] - 362

          console.log(`height:${newHeight}px`)
          let resultlist = document.getElementsByClassName("resultlist");
          resultlist[0].style.height = `${newHeight}px`
        }
        /*if(this.media_match > 2 ){
           newHeight = vh  -120
           console.log( newHeight +' '+  this.position[1] )
           let resultlist = document.getElementsByClassName("top-resultlist");
           resultlist[0].style.height = `${newHeight}px`
         } */


        //if(this.media_match <= 2){

        //}


        document.getElementsByTagName("html")[0].style.overflowY = "hidden"
        if (this.media_match <= 2) {

        } else {

        }


        this.$store.commit("ClickBody", false);
        this.$store.commit("openCategoryMenu", true);
        this.$store.commit("openCategoriesMenu", false);
        this.$store.commit("magic_flag", true);
      } else if (val == false) {


        document.getElementsByTagName("html")[0].style.overflowY = ""
        /*let containerSearchInBanner = document.getElementsByClassName("banner-search-cont");
        containerSearchInBanner[0].style.height = 'auto';*/


        //this.$store.commit("ClickBody", true);
        //this.$store.commit("openCategoryMenu", false);
        //this.$store.commit("openCategoriesMenu", true);
        this.$store.commit("ClickBody", true);
        this.$store.commit("magic_flag", false);
      }
    },
    position(newVal) {
      if (newVal[1] > 200) {
        this.showInner = false
      } else {
        this.showInner = true
      }
    },
    media_match(newVal) {
      if (
        newVal === 0
      ) {
        this.showSearchForm = false
        this.magic_flag = false
      } else {
        this.showSearchForm = true
      }
    }
  },
  methods: {
    async searchingAction() {

      this.loading = true
      this.busqueda = this.$refs.busquedaRef.value.trim();
      this.eventBriteEvents = await eventsEvenbrite.getEvents({ name: this.busqueda })

      await this.searching();
      this.searchedEvents = [];
      let eventsTevo = await eventsApi.actionGetEventTevoQuery2({
        //only_with_available_tickets: true,
        //order_by: "events.occurs_at ASC",
        "occurs_at.gte": momentFunctions.occurs_at_gte(),
        //order_by: "events.popularity_score DESC",
        order_by: "events.occurs_at ASC",
        page: 1,
        per_page: 50,
        q: this.busqueda,
      });
      eventsTevo = eventsFunctions.groupEventsByName(eventsTevo)

      this.$gtm.push({
        event: "Searching",
        search: {
          value: this.busqueda,
        },
      });



      this.searchedEvents = eventsTevo;
      this.magic_flag = true
      this.loading = false
    },
    showResultsList() {
      return this.magic_flag
    },
    onFocusSearchInput() {
      if (this.isSearchPage) {
        //this.magic_flag = false
      }
      if (this.searchInputChange) {
        //this.magic_flag = true
      }

    },
    handleInputChange() {

    },

    magicFlag() {

    },
    onClose() {
      console.log('onClose')
      this.magic_flag = false;
      this.$store.commit("ClickBody", true)
    },
    searchSubmit() {

      /*this.$router.push({path: '/search', query:{q: this.searchInput}})
      this.magic_flag = false
      this.$refs.busquedaRef.blur(); */


      var searchCategories = document.getElementsByClassName("searchCategories");
      var searchPerformers = document.getElementsByClassName("searchPerformers");
      var searchEvents = document.getElementsByClassName("searchEvents");
      var searchCities = document.getElementsByClassName("searchCities");

      let arrayChoose = null
      if (Array.from(searchCategories).length > 0) {

        arrayChoose = Array.from(searchCategories)
      } else if (Array.from(searchPerformers).length > 0) {

        arrayChoose = Array.from(searchPerformers)

      } else if (Array.from(searchEvents).length > 0) {
        arrayChoose = Array.from(searchEvents)
      } else if (Array.from(searchCities).length > 0) {
        arrayChoose = Array.from(searchCities)
      }

      console.log(arrayChoose)

      if (arrayChoose)
        this.$router.push(arrayChoose[0].getAttribute("href"))

    },
    menuMovilSwiper() {
      this.show_menu_status = false;
      document.getElementsByTagName("html")[0].style.overflowY = "";
      document.getElementsByTagName("body")[0].style.position = "";
      document.body.style.paddingRight = "0";
    },

    performerImageUrl(performer) {
      let fileName = `${performer.slug}/thumb@search.jpg`;
      return `https://firebasestorage.googleapis.com/v0/b/peppertix-org.appspot.com/o/${encodeURI(
        fileName
      ).replace("/", "%2F")}?alt=media`;
    },
    searchFormSubmit() {
      this.$router.push(this.search_query);
    },
    headerKeyUp(event) {
      if (event.key == "ArrowDown") {
        if (this.overCategories == true) {
          if (this.indexCategories == this.musicCategories.length - 1) {
            this.indexCategories = -1;
            this.overCategories = false;
            this.overPerformers = true;
            this.indexPerformers = 0;
          }
          this.indexCategories++;
          if (this.indexCategories > this.musicCategories.length - 1) {
            this.indexCategories = -1;
            this.overCategories = false;
            this.overPerformers = true;
            this.indexPerformers = 0;
          }
        } else if (this.overPerformers == true) {
          this.indexPerformers++;
          if (this.indexPerformers > this.searchedArtists.length - 1) {
            this.indexPerformers = -1;
            this.overCategories = true;
            this.overPerformers = false;
          }
        }
      } else if (event.key == "ArrowUp") {
        if (this.overCategories == true) {
          this.indexCategories--;
          if (this.indexCategories < 0) {
            this.indexCategories = 0;
          }
        } else if (this.overPerformers == true) {
          this.indexPerformers--;
          if (this.indexPerformers < 0) {
            this.indexPerformers = -1;
            this.overCategories = true;
            this.overPerformers = false;

            this.indexCategories = this.musicCategories.length - 1;
          }
        }
      }

      if (event.key == "ArrowDown" || event.key == "ArrowUp") {
        if (this.overCategories == true) {
          if (
            this.indexCategories >= 0 &&
            this.indexCategories < this.musicCategories.length
          ) {
            this.musicCategories[this.indexCategories].seleccionada = true;
          }
        } else if (this.overPerformers == true) {
          if (
            this.indexPerformers >= 0 &&
            this.indexPerformers < this.searchedArtists.length
          ) {
            this.searchedArtists[this.indexPerformers].seleccionada = true;
          }
        }

        for (let i = 0; i < this.musicCategories.length; i++) {
          if (this.indexCategories != i) {
            this.musicCategories[i].seleccionada = false;
          }
        }

        for (let i = 0; i < this.searchedArtists.length; i++) {
          if (this.indexPerformers != i) {
            this.searchedArtists[i].seleccionada = false;
          }
        }
      }
    },
    mouseLeaveCategory(category) {
      if (category) {
        category.seleccionada = false;
      }
    },
    mouseOverCity() { },
    mouseLeaveCity() { },
    mouseOverCategory(category) {
      if (category) {
        category.seleccionada = true;
        //this.busqueda = category.name;
        this.selectedMatched = {
          name: category.name,
          type: "category",
          slug: `/${category.slug}`,
        };
      }
    },
    mouseOverPerformer(performer) {
      if (performer) {
        let artist = performer;
        let slug = artist.slug
          ? `/results/${artist.slug}`
          : `/results/${artist.name}`;
        this.selectedMatched = {
          type: "performer",
          name: artist.name,
          slug: slug,
        };
      }
    },


    async searching() {


      if (this.busqueda.length === 0) {
        //this.magic_flag = false;
      } else if (this.busqueda.length > 0) {
        this.magic_flag = true;
        this.blockearSearch = true;
        /*this.musicCategories.length = 0;
          this.searchedArtists.length = 0;
          this.searchedEvents.length = 0;*/

        //this.busqueda = document.getElementById("busquedaMovil").value;
        this.searchedArtists = []
        let params = {
          q: this.busqueda,
          page: 1,
          per_page: 3,
        };


        let [
          musicCategories,
          performersTevo,
          //eventsTevo,
          cities,
        ] = await Promise.all([
          categoriesApi.getCategoryByName(this.busqueda),

          performersApi.searchPerformersLikeName({
            name: this.busqueda,
            limit: 10,
          }),
          //eventsApi.actionGetEventTevoQuery2(paramsEvents),
          citiesApi.searchCities({
            name: this.busqueda,
            //score: 10,
            page: 1,
            limite: 4,
          }),
        ]).catch((error) => { });

        this.cities = cities;

        musicCategories = musicCategories.data;
        for (let i = 0; i < musicCategories.length; i++) {
          if (musicCategories[i].name) {
            musicCategories[i].seleccionada = false;
          }
        }
        this.musicCategories = musicCategories;
        this.indexCategories = -1;

        /**
         *
         */
        //let performers = performersTevo.data.performers;

        let performers = performersTevo;
        for (let i = 0; i < performers.length; i++) {
          if (performers[i].name) {
            performers[i].seleccionada = false;
          }
        }
        this.searchedArtists = performers;

        if (this.musicCategories.length > 0) {
          this.selectedMatched = {
            type: "category",
            name: this.musicCategories[0].name,
            slug: `/${this.musicCategories[0].slug}`,
          };
        } else if (this.searchedArtists.length > 0) {
          let artist = this.searchedArtists[0];
          let slug = artist.slug
            ? `/results/${artist.slug}`
            : `/results/${artist.name}`;
          this.selectedMatched = {
            type: "performer",
            name: artist.name,
            slug: slug,
          };
        } else if (this.searchedEvents.length > 0) {
          let evento = this.searchedEvents[0];
          let slug = this.getRoute(evento);
          this.selectedMatched = {
            type: "event",
            name: evento.name,
            slug: slug,
          };
        }
        setTimeout(function () { }, 0);

        this.blockearSearch = false;
        //this.$refs.busquedaMovil.click();
      }
    },
    getEventBriteRoute(event) {
      console.log(event.name.text)
      let eventName = event.name.text
      eventName = eventName.replace(/ /gi, "-");
      eventName = eventName.replace(/ /gi, "-");
      eventName = eventName.replace(/,/gi, "-");
      eventName = eventName.replace(/--/gi, "-");
      eventName = eventName.toLowerCase();
      return `/e/${eventName}/${event.id}`
    },
    getRoute(event) {

      let performerName = Array.isArray(event.performances) ? event.performances[0]?.performer?.name : event.performances?.performer?.name;
      if (!performerName) return '/';
      performerName = performerName.replace(/ /gi, "-");
      performerName = performerName.replace(/,/gi, "-");
      performerName = performerName.replace(/--/gi, "-");
      performerName = performerName.toLowerCase();

      let venueLocation = event.venue.location;
      if (!venueLocation) return '/';
      venueLocation = venueLocation.replace(/ /gi, "-");
      venueLocation = venueLocation.replace(/,/gi, "-");
      venueLocation = venueLocation.replace(/--/gi, "-");
      venueLocation = venueLocation.toLowerCase();

      let venueName = event.venue.name;


      if (!venueName) return '/';
      venueName = venueName.replace(/ /gi, "-");
      venueName = venueName.replace(/,/gi, "-");
      venueName = venueName.replace(/--/gi, "-");
      venueName = venueName.toLowerCase();

      let hour = event.hour;
      hour = hour.replace(/ /gi, "-");
      hour = hour.replace(/:/gi, "-");
      let eventFecha = `${event.year}-${event.month2}-${event.day2}-${hour}`;

      let ruta = `/book/${performerName}/${venueLocation}-${venueName}-${eventFecha}/${event.id}`;

      return ruta;
    },

    closeInviteFriends() {
      this.toogleInviteFriends = false;
    },
    clickInvitation(invitation) {
      let params = {
        inviterId: invitation.inviterId,
        eventId: invitation.eventId,
      };

      invitationsApi.getInvitation(params).then((invitationRes) => {
        this.invitationSelected = invitationRes;
        this.performer = this.invitationSelected.performer;
        this.toogleInviteFriends = true;
        //this.performer =  ,
        /////invitationSelected: {},
      });
    },
    clickViewNotifications() {
      this.toogleInvitation = !this.toogleInvitation;
    },
    ClickBody() {
      this.$store.commit("ClickBody", false);


    },
    upatePerformer(performer) {
      let performer_id = performer.performer_id;
      this.$store
        .dispatch("performers/actionUpdatePerformerById", {
          performer_id,
        })
        .then((response) => {
          performer.imageUrl = response.data.imageUrl;
        });
    },
    async searchClick() {
      if (this.searchedArtists.length > 0
        || this.musicCategories.length > 0

        || this.searchedEvents.length > 0
        || this.cities.length > 0
        || this.eventBriteEvents.length > 0) {

        this.magic_flag = true;

        return

      }
      if (this.searchInput.length === 0) {
        //this.magic_flag = false;

        let [musicCategories, cities] = await Promise.all([
          categoriesApi.getCategoriesByCategoriesId("[1,54,68,73]"),
          citiesApi.getCitiesBySlugs(
            `["san-francisco-ca","new-york-ny","las-vegas-nv","anaheim-ca"]`
          ),
        ]);

        this.cities = cities;


        this.magic_flag = true;

        // Don't delete the following comment text
        // if(this.isSearchPage  && this.media_match <= 2 ){
        //    this.magic_flag = false;     * 
        // }else{
        //   this.magic_flag = true; *
        // }

        musicCategories = musicCategories.data;

        for (let i = 0; i < musicCategories.length; i++) {
          musicCategories[i].seleccionada = false;
        }

        this.musicCategories = musicCategories;
        this.indexCategories = -1;

        let params = {
          category_id: 54,
          category_tree: true,
          //only_with_upcoming_events: true,
          order_by: "performers.popularity_score DESC",
          page: 1,
          per_page: 24,
        };

        let [performersTevo] = await Promise.all([
          //performersApi.getPerformers(params)
          performersApi.getMorePopularPerformers(),
        ]);

        //let performers = performersTevo.data.performers;
        let performers = performersTevo;

        for (let i = 0; i < performers.length; i++) {
          performers[i].id = performers[i].performer_id;
          performers[i].seleccionada = false;
        }
        this.searchedArtists = performers;
        //this.setImagesPerformers(performers);
        /**
         *
         *
         */
      } else if (this.searchInput.length > 0) {
        this.searchingAction()
        this.magic_flag = true;
      }
    },

    async setImagesPerformers(performers = []) {
      return new Promise(async (resolve, reject) => {
        let matriz_ids = "[";

        for (let i = 0; i < performers.length; i++) {
          if (i < performers.length - 1) {
            matriz_ids += performers[i].id + ",";
          } else if (i == performers.length - 1) {
            matriz_ids += performers[i].id;
          }
        }
        matriz_ids += "]";

        let [performersBD] = await Promise.all([
          performersApi.getArtistsByPerformersId(matriz_ids),
        ]);

        for (let k = 0; k < performers.length; k++) {
          const index = performersBD.findIndex(
            (item) => item.performer_id == performers[k].id
          );

          performers[k].imageUrl =
            "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";

          if (index >= 0) {
            performers[k].imageUrl = performersBD[index].imageUrl;
          } else {
          }
        }

        this.searchedArtists = performers;
        resolve(this.searchedArtists);

        for (let k = 0; k < performers.length; k++) {
          const index = performersBD.findIndex(
            (item) => item.performer_id == performers[k].id
          );

          performers[k].imageUrl =
            "https://ticketdelivery.herokuapp.com/images/no_artist_images_300x300.jpg";

          if (index >= 0) {
            performers[k].imageUrl = performersBD[index].imageUrl;
          } else {
            let performer_id = performers[k].id;

            performersApi
              .updatePerformerById(performer_id)
              .then((performerUpdated) => {
                performers[k].imageUrl = performerUpdated.data.imageUrl;
              });
          }
        }
      });
    },
    navigateToHome() {
      let ruta = {
        path: "/",
      };
      this.$router.push(ruta);
    },
    saveArtist(name) {
      //TODO:
      this.magic_flag = false;
      // this.$store.commit("performers/setSearchedArtist", name);
    },
    mouseOver: function () {
      this.active = true;
    },
    showmenu: function () {
      if (this.show_menu_status == false) {
        this.show_menu_status = true;

        document
          .getElementsByTagName("html")[0]
          .setAttribute("style", "overflow-y: hidden !important");
        document
          .getElementsByTagName("body")[0]
          .setAttribute("style", "position: fixed !important");
        //document.body.style.paddingRight = "17px";
      } else {
        this.show_menu_status = false;

        document.getElementsByTagName("html")[0].style.overflowY = "";
        document.getElementsByTagName("body")[0].style.position = "";

        document.body.style.paddingRight = "0";
      }
    },

    searchback: function () {
      this.toogleShowBackPage = true;
      this.show_header_search = false;
      this.show_search_action = true;
      this.magic_flag = false;
    },
    showsearch: function () {
      /*this.toogleShowBackPage = false;
      this.show_header_search = true;
      this.show_search_action = false;

      let busquedaMovil = document.getElementById("busquedaMovil");
      busquedaMovil.focus();
      //this.$refs.busquedaMovil.focus();
      this.$refs.busquedaMovil.click();
      this.$refs.busquedaMovil.focus();
      document.getElementById("busquedaMovil").focus();

      setTimeout(function() {
        if (document.getElementById("busquedaMovil")) {
          document.getElementById("busquedaMovil").focus();
        }
      }, 0);*/
    },
    mouseLeaveSearch: function () {
      //this.magic_flag = false;
    },
    mouseLeave: function () {
      this.active = false;
    },
    onLogout() {
      this.$store.dispatch("user/logout");

      this.active = false;

      if (
        this.$route.path.includes("user") ||
        this.$route.path.includes("finish_purchase") ||
        this.$route.path.includes("checkout")
      ) {
        this.$router.push(`/`);
      } else if (this.$route.path.includes("/subscription/checkout")) {
        this.$router.push(`/subscription`);
      } else if (this.$route.path.includes("/subscription/checkout")) {
      }
    },
    closeSignUp() {
      this.toogleSignUp = false;
    },
    openSignUp() {
      this.toogleSignUp = true;
    },
    closeSignIn() {
      this.toogleSignIn = false;
    },
    openSignIn() {
      this.toogleSignIn = true;
    },

    errorArtistImage(artist, index) {
      artist.imageUrl =
        "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_concert.jpg?alt=media&token=08e0b469-5247-4f84-9ba6-9eb36ccee2e4";
    },
    imageLoadOnError() {
      this.$store.commit("user/storeImgSrc", "/img/home/profile.svg");
    },
  },
  destroyed: function () {


  },
};
