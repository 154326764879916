
let LOGGER = process.env.LOGGER

const logger = function()
{
    var oldConsoleLog = null;
    var pub = {};

    pub.enableLogger =  function enableLogger()
                        {
                            if(oldConsoleLog == null)
                                return;

                            window['console']['log'] = oldConsoleLog;
                        };

    pub.disableLogger = function disableLogger()
                        {
                            oldConsoleLog = console.log;
                            window['console']['log'] = function() {};
                        };


    pub.logger = function logger(){
  
      if(LOGGER === 'false'){
        pub.disableLogger()
      }else{
        pub.enableLogger()
      }

    }


    return pub;
}();





export default logger
