import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5c0016f7 = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _dbc2eb6e = () => interopDefault(import('../pages/cities/index.vue' /* webpackChunkName: "pages/cities/index" */))
const _737e2ae2 = () => interopDefault(import('../pages/contacto/index.vue' /* webpackChunkName: "pages/contacto/index" */))
const _ef79fa36 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _693986fa = () => interopDefault(import('../pages/finish_purchase/index.vue' /* webpackChunkName: "pages/finish_purchase/index" */))
const _d7b3dc00 = () => interopDefault(import('../pages/nearme/index.vue' /* webpackChunkName: "pages/nearme/index" */))
const _5ff915ef = () => interopDefault(import('../pages/oxxo_order/index.vue' /* webpackChunkName: "pages/oxxo_order/index" */))
const _7b82c2ac = () => interopDefault(import('../pages/oxxo_reference/index.vue' /* webpackChunkName: "pages/oxxo_reference/index" */))
const _6dbd09e8 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _eefa6366 = () => interopDefault(import('../pages/spotify_auth/index.vue' /* webpackChunkName: "pages/spotify_auth/index" */))
const _2dd23f5d = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _369bd57e = () => interopDefault(import('../pages/legal/license.vue' /* webpackChunkName: "pages/legal/license" */))
const _cedaddc8 = () => interopDefault(import('../pages/legal/privacy_policy.vue' /* webpackChunkName: "pages/legal/privacy_policy" */))
const _3fdb1098 = () => interopDefault(import('../pages/legal/terms_of_service.vue' /* webpackChunkName: "pages/legal/terms_of_service" */))
const _73c4563c = () => interopDefault(import('../pages/news/coronavirus.vue' /* webpackChunkName: "pages/news/coronavirus" */))
const _3e51ad81 = () => interopDefault(import('../pages/subscription/benefits.vue' /* webpackChunkName: "pages/subscription/benefits" */))
const _e7bc71ea = () => interopDefault(import('../pages/subscription/checkout.vue' /* webpackChunkName: "pages/subscription/checkout" */))
const _73fb17fa = () => interopDefault(import('../pages/subscription/confirmation.vue' /* webpackChunkName: "pages/subscription/confirmation" */))
const _506004dc = () => interopDefault(import('../pages/subscription/terms.vue' /* webpackChunkName: "pages/subscription/terms" */))
const _469582b3 = () => interopDefault(import('../pages/user/events/index.vue' /* webpackChunkName: "pages/user/events/index" */))
const _0aea7345 = () => interopDefault(import('../pages/user/faves/index.vue' /* webpackChunkName: "pages/user/faves/index" */))
const _b21a0d32 = () => interopDefault(import('../pages/user/payment-methods/index.vue' /* webpackChunkName: "pages/user/payment-methods/index" */))
const _7b5f257d = () => interopDefault(import('../pages/user/settings/index.vue' /* webpackChunkName: "pages/user/settings/index" */))
const _7b4f3a77 = () => interopDefault(import('../pages/user/subscription/index.vue' /* webpackChunkName: "pages/user/subscription/index" */))
const _0114c694 = () => interopDefault(import('../pages/auth/pop/google/index.vue' /* webpackChunkName: "pages/auth/pop/google/index" */))
const _415f5ed2 = () => interopDefault(import('../pages/user/payment-methods/subscription_payment_methods.vue' /* webpackChunkName: "pages/user/payment-methods/subscription_payment_methods" */))
const _bd83922e = () => interopDefault(import('../pages/email/verification/_email/_userWebId/_token/index.vue' /* webpackChunkName: "pages/email/verification/_email/_userWebId/_token/index" */))
const _54f10ce2 = () => interopDefault(import('../pages/subscription/cancel/_userWebId/_subscriptionId/_token/index.vue' /* webpackChunkName: "pages/subscription/cancel/_userWebId/_subscriptionId/_token/index" */))
const _17bcf2e1 = () => interopDefault(import('../pages/book/_search/index.vue' /* webpackChunkName: "pages/book/_search/index" */))
const _c07f8024 = () => interopDefault(import('../pages/checkout/_ticket_group_id/index.vue' /* webpackChunkName: "pages/checkout/_ticket_group_id/index" */))
const _f58a2874 = () => interopDefault(import('../pages/reset-password/_token/index.vue' /* webpackChunkName: "pages/reset-password/_token/index" */))
const _03379727 = () => interopDefault(import('../pages/results/_name/index.vue' /* webpackChunkName: "pages/results/_name/index" */))
const _7e3b3484 = () => interopDefault(import('../pages/e/_name/_id.vue' /* webpackChunkName: "pages/e/_name/_id" */))
const _36dacfdf = () => interopDefault(import('../pages/book/_search/_venue_date/_event_id/index.vue' /* webpackChunkName: "pages/book/_search/_venue_date/_event_id/index" */))
const _24632d27 = () => interopDefault(import('../pages/cities/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/cities/_parent/_category_city/_city" */))
const _208684f2 = () => interopDefault(import('../pages/get_promo/_name/_firstName/_lastName/_email/index.vue' /* webpackChunkName: "pages/get_promo/_name/_firstName/_lastName/_email/index" */))
const _5caa75ea = () => interopDefault(import('../pages/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/_parent/_category_city/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app",
    component: _5c0016f7,
    name: "app___en"
  }, {
    path: "/cities",
    component: _dbc2eb6e,
    name: "cities___en"
  }, {
    path: "/contacto",
    component: _737e2ae2,
    name: "contacto___en"
  }, {
    path: "/es",
    component: _ef79fa36,
    name: "index___es"
  }, {
    path: "/finish_purchase",
    component: _693986fa,
    name: "finish_purchase___en"
  }, {
    path: "/nearme",
    component: _d7b3dc00,
    name: "nearme___en"
  }, {
    path: "/oxxo_order",
    component: _5ff915ef,
    name: "oxxo_order___en"
  }, {
    path: "/oxxo_reference",
    component: _7b82c2ac,
    name: "oxxo_reference___en"
  }, {
    path: "/search",
    component: _6dbd09e8,
    name: "search___en"
  }, {
    path: "/spotify_auth",
    component: _eefa6366,
    name: "spotify_auth___en"
  }, {
    path: "/subscription",
    component: _2dd23f5d,
    name: "subscription___en"
  }, {
    path: "/es/app",
    component: _5c0016f7,
    name: "app___es"
  }, {
    path: "/es/cities",
    component: _dbc2eb6e,
    name: "cities___es"
  }, {
    path: "/es/contacto",
    component: _737e2ae2,
    name: "contacto___es"
  }, {
    path: "/es/finish_purchase",
    component: _693986fa,
    name: "finish_purchase___es"
  }, {
    path: "/es/nearme",
    component: _d7b3dc00,
    name: "nearme___es"
  }, {
    path: "/es/oxxo_order",
    component: _5ff915ef,
    name: "oxxo_order___es"
  }, {
    path: "/es/oxxo_reference",
    component: _7b82c2ac,
    name: "oxxo_reference___es"
  }, {
    path: "/es/search",
    component: _6dbd09e8,
    name: "search___es"
  }, {
    path: "/es/spotify_auth",
    component: _eefa6366,
    name: "spotify_auth___es"
  }, {
    path: "/es/subscription",
    component: _2dd23f5d,
    name: "subscription___es"
  }, {
    path: "/legal/license",
    component: _369bd57e,
    name: "legal-license___en"
  }, {
    path: "/legal/privacy_policy",
    component: _cedaddc8,
    name: "legal-privacy_policy___en"
  }, {
    path: "/legal/terms_of_service",
    component: _3fdb1098,
    name: "legal-terms_of_service___en"
  }, {
    path: "/news/coronavirus",
    component: _73c4563c,
    name: "news-coronavirus___en"
  }, {
    path: "/subscription/benefits",
    component: _3e51ad81,
    name: "subscription-benefits___en"
  }, {
    path: "/subscription/checkout",
    component: _e7bc71ea,
    name: "subscription-checkout___en"
  }, {
    path: "/subscription/confirmation",
    component: _73fb17fa,
    name: "subscription-confirmation___en"
  }, {
    path: "/subscription/terms",
    component: _506004dc,
    name: "subscription-terms___en"
  }, {
    path: "/user/events",
    component: _469582b3,
    name: "user-events___en"
  }, {
    path: "/user/faves",
    component: _0aea7345,
    name: "user-faves___en"
  }, {
    path: "/user/payment-methods",
    component: _b21a0d32,
    name: "user-payment-methods___en"
  }, {
    path: "/user/settings",
    component: _7b5f257d,
    name: "user-settings___en"
  }, {
    path: "/user/subscription",
    component: _7b4f3a77,
    name: "user-subscription___en"
  }, {
    path: "/auth/pop/google",
    component: _0114c694,
    name: "auth-pop-google___en"
  }, {
    path: "/es/legal/license",
    component: _369bd57e,
    name: "legal-license___es"
  }, {
    path: "/es/legal/privacy_policy",
    component: _cedaddc8,
    name: "legal-privacy_policy___es"
  }, {
    path: "/es/legal/terms_of_service",
    component: _3fdb1098,
    name: "legal-terms_of_service___es"
  }, {
    path: "/es/news/coronavirus",
    component: _73c4563c,
    name: "news-coronavirus___es"
  }, {
    path: "/es/subscription/benefits",
    component: _3e51ad81,
    name: "subscription-benefits___es"
  }, {
    path: "/es/subscription/checkout",
    component: _e7bc71ea,
    name: "subscription-checkout___es"
  }, {
    path: "/es/subscription/confirmation",
    component: _73fb17fa,
    name: "subscription-confirmation___es"
  }, {
    path: "/es/subscription/terms",
    component: _506004dc,
    name: "subscription-terms___es"
  }, {
    path: "/es/user/events",
    component: _469582b3,
    name: "user-events___es"
  }, {
    path: "/es/user/faves",
    component: _0aea7345,
    name: "user-faves___es"
  }, {
    path: "/es/user/payment-methods",
    component: _b21a0d32,
    name: "user-payment-methods___es"
  }, {
    path: "/es/user/settings",
    component: _7b5f257d,
    name: "user-settings___es"
  }, {
    path: "/es/user/subscription",
    component: _7b4f3a77,
    name: "user-subscription___es"
  }, {
    path: "/user/payment-methods/subscription_payment_methods",
    component: _415f5ed2,
    name: "user-payment-methods-subscription_payment_methods___en"
  }, {
    path: "/es/auth/pop/google",
    component: _0114c694,
    name: "auth-pop-google___es"
  }, {
    path: "/es/user/payment-methods/subscription_payment_methods",
    component: _415f5ed2,
    name: "user-payment-methods-subscription_payment_methods___es"
  }, {
    path: "/",
    component: _ef79fa36,
    name: "index___en"
  }, {
    path: "/es/email/verification/:email?/:userWebId?/:token",
    component: _bd83922e,
    name: "email-verification-email-userWebId-token___es"
  }, {
    path: "/es/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _54f10ce2,
    name: "subscription-cancel-userWebId-subscriptionId-token___es"
  }, {
    path: "/es/book/:search",
    component: _17bcf2e1,
    name: "book-search___es"
  }, {
    path: "/es/checkout/:ticket_group_id",
    component: _c07f8024,
    name: "checkout-ticket_group_id___es"
  }, {
    path: "/es/reset-password/:token",
    component: _f58a2874,
    name: "reset-password-token___es"
  }, {
    path: "/es/results/:name",
    component: _03379727,
    name: "results-name___es"
  }, {
    path: "/es/e/:name?/:id?",
    component: _7e3b3484,
    name: "e-name-id___es"
  }, {
    path: "/email/verification/:email?/:userWebId?/:token",
    component: _bd83922e,
    name: "email-verification-email-userWebId-token___en"
  }, {
    path: "/es/book/:search?/:venue_date/:event_id",
    component: _36dacfdf,
    name: "book-search-venue_date-event_id___es"
  }, {
    path: "/es/cities/:parent/:category_city?/:city?",
    component: _24632d27,
    name: "cities-parent-category_city-city___es"
  }, {
    path: "/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _54f10ce2,
    name: "subscription-cancel-userWebId-subscriptionId-token___en"
  }, {
    path: "/es/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _208684f2,
    name: "get_promo-name-firstName-lastName-email___es"
  }, {
    path: "/book/:search",
    component: _17bcf2e1,
    name: "book-search___en"
  }, {
    path: "/checkout/:ticket_group_id",
    component: _c07f8024,
    name: "checkout-ticket_group_id___en"
  }, {
    path: "/reset-password/:token",
    component: _f58a2874,
    name: "reset-password-token___en"
  }, {
    path: "/results/:name",
    component: _03379727,
    name: "results-name___en"
  }, {
    path: "/e/:name?/:id?",
    component: _7e3b3484,
    name: "e-name-id___en"
  }, {
    path: "/book/:search?/:venue_date/:event_id",
    component: _36dacfdf,
    name: "book-search-venue_date-event_id___en"
  }, {
    path: "/cities/:parent/:category_city?/:city?",
    component: _24632d27,
    name: "cities-parent-category_city-city___en"
  }, {
    path: "/es/:parent/:category_city?/:city?",
    component: _5caa75ea,
    name: "parent-category_city-city___es"
  }, {
    path: "/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _208684f2,
    name: "get_promo-name-firstName-lastName-email___en"
  }, {
    path: "/:parent/:category_city?/:city?",
    component: _5caa75ea,
    name: "parent-category_city-city___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
