/* eslint-disable */

import configVars from "../config/config_vars";

const baseUrl = configVars.appBaseUrl;

 

import querystring from "querystring";

import axios from "axios";
import usersApi from "@/api/users";

import Cookie from "js-cookie";

export const state = () => ({
  idToken: null,
  userId: null,
  user: null,
  user_image: "/img/home/profile.svg",
  isAuthenticated: false,
  error: null,
  seleccionados: null,
  performersToSave: null,
  performers: null,
  sports: null,
  theatres: null,
  special_events: null,
  event_brite_events: [],
  messengerUser: null,
  Invitations: [],
  receivedInvitations: [],
  isFavorite: false,
  availablePepperCash: 0,
  requestedPepperCash: 0,
  userIsVIPMember: false,
  creditCards: [],
  crediCardIdSelected: null,
  client_id: null,
  saveCC: false,
  spotifyToken: null,
  preSaveEventBriteEvent: null,
  eventBriteFave: false,
  userHasPromoCode: false,
  promoCodeForApply: null,
  signinGetReadyToAddPromo: false,
  prospect: false,
});

export const getters = {
  prospect(state){
    return state.prospect
  },
  signinGetReadyToAddPromo(state) {
    return state.signinGetReadyToAddPromo;
  },
  promoCodeForApply(state) {
    return state.promoCodeForApply;
  },
  userHasPromoCode(state) {
    return state.userHasPromoCode;
  },
  preSaveEventBriteEvent(state) {
    return state.preSaveEventBriteEvent;
  },
  eventBriteFave(state) {
    return state.eventBriteFave;
  },

  event_brite_events(state) {
    return state.event_brite_events;
  },

  spotifyToken(state) {
    return state.spotifyToken;
  },
  saveCC(state) {
    return state.saveCC;
  },
  creditCards(state) {
    return state.creditCards;
  },
  crediCardIdSelected(state) {
    return state.crediCardIdSelected;
  },
  client_id(state) {
    return state.client_id;
  },
  userIsVIPMember(state) {
    return state.userIsVIPMember;
  },
  availablePepperCash(state) {
    return state.availablePepperCash;
  },
  requestedPepperCash(state) {
    return state.requestedPepperCash;
  },
  user(state) {
    return state.user;
  },
  idToken(state) {
    return state.idToken;
  },
  messengerUser(state) {
    return state.messengerUser;
  },

  imgSrc(state) {
    return state.user_image;
  },
  isAutenticated(state) {
    return state.idToken !== null;
  },

  isFavorite(state) {
    return state.isFavorite;
  },

  getError(state) {
    return state.error;
  },
  performers(state) {
    return state.performers;
  },
  sports(state) {
    return state.sports;
  },

  theatres(state) {
    return state.theatres;
  },
  special_events(state) {
    return state.special_events;
  },

  getSeleccionados(state) {
    return state.seleccionados;
  },
  getperformersToSave(state) {
    return state.performersToSave;
  },
  Invitations(state) {
    return state.Invitations;
  },

  receivedInvitations(state) {
    return state.receivedInvitations;
  },
};

export const mutations = {
  prospect(state, prospect){
    state.prospect = prospect
  },
  signinGetReadyToAddPromo(state, signinGetReadyToAddPromo) {
    state.signinGetReadyToAddPromo = signinGetReadyToAddPromo;
  },

  promoCodeForApply(state, promoCodeForApply) {
    state.promoCodeForApply = promoCodeForApply;
  },
  userHasPromoCode(state, userHasPromoCode) {
    state.userHasPromoCode = userHasPromoCode;
  },
  preSaveEventBriteEvent(state, preSaveEventBriteEvent) {
    state.preSaveEventBriteEvent = preSaveEventBriteEvent;
  },
  eventBriteFave(state, eventBriteFave) {
    state.eventBriteFave = eventBriteFave;
  },
  event_brite_events(state, event_brite_events) {
    state.event_brite_events = event_brite_events;
  },
  spotifyToken(state, spotifyToken) {
    state.spotifyToken = spotifyToken;
  },
  saveCC(state, saveCC) {
    state.saveCC = saveCC;
  },
  creditCards(state, creditCards) {
    state.creditCards = creditCards;
  },
  deleteCreditCard(state, id) {
    state.creditCards = state.creditCards.filter((item) => item.id != id);
  },
  addCreditCard(state, newCreditCard) {
    let index = state.creditCards.findIndex(
      (item) => item.id == newCreditCard.id
    );
    if (index < 0) {
      state.creditCards = [newCreditCard, ...state.creditCards];
    }
  },
  crediCardIdSelected(state, crediCardIdSelected) {
    state.crediCardIdSelected = crediCardIdSelected;
  },
  client_id(state, client_id) {
    state.client_id = client_id;
  },
  userIsVIPMember(state, userIsVIPMember) {
    state.userIsVIPMember = userIsVIPMember;
  },
  availablePepperCash(state, availablePepperCash) {
    state.availablePepperCash = availablePepperCash;
  },
  requestedPepperCash(state, requestedPepperCash) {
    state.requestedPepperCash = requestedPepperCash;
  },
  storeMessengerUser(state, messengerUser) {
    state.messengerUser = messengerUser;
  },
  authUser(state, userData) {
    state.idToken = userData.token;
    state.userId = userData.userId;
  },
  clearToken(state) {
    state.idToken = null;
  },
  storeIdToken(state, idToken) {
    state.idToken = idToken;
  },
  storeUser(state, user) {
    state.user = user;
  },
  storeImgSrc(state, imgSrc) {
    state.user_image = imgSrc;
  },

  storeIsFavorite(state, isFavorite) {
    state.isFavorite = isFavorite;
  },

  storePerformers(state, performers) {
    state.performers = performers;
  },

  editArtist(state, artist) {
    const index = state.performers.findIndex(
      (f) => f.performer_id === artist.performer_id
    );
    artist.imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_concert.jpg?alt=media&token=08e0b469-5247-4f84-9ba6-9eb36ccee2e4";
    state.performers[index] = artist;
  },

  storeSports(state, sports) {
    state.sports = sports;
  },

  editSport(state, sport) {
    const index = state.sports.findIndex(
      (f) => f.performer_id === sport.performer_id
    );

    sport.imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_sport.jpg?alt=media&token=91c2ba33-3497-4f22-b958-a482c8711659";
    state.sports[index] = sport;
  },

  storeTheatres(state, theatres) {
    state.theatres = theatres;
  },

  editTheatre(state, theatre) {
    const index = state.theatres.findIndex(
      (f) => f.performer_id === theatre.performer_id
    );
    theatre.imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_theater.jpg?alt=media&token=0a5a0cf5-cfac-4e29-b97e-6c168f8eec19";
    state.theatres[index] = theatre;
  },

  storeSpecial_events(state, special_events) {
    state.special_events = special_events;
  },

  editSpecialEvent(state, special_event) {
    const index = state.special_events.findIndex(
      (invita) => invita.performer_id === special_event.performer_id
    );
    special_event.imageUrl =
      "https://firebasestorage.googleapis.com/v0/b/pepperbussines.appspot.com/o/no-images%2Fno_image_special_event.jpg?alt=media&token=35204645-dda7-432b-bed9-1387f73246dc";
    state.special_events[index] = special_event;
  },

  storeSeleccionados(state, seleccionados) {
    state.seleccionados = seleccionados;
  },
  clearAuthData(state) {
    state.client_id = null;
    state.creditCards = [];
    state.crediCardIdSelected = null;
    state.saveCC = false;
    state.idToken = null;
    state.userId = null;
    state.user = null;
    state.user_image = "";
    state.Invitations = [];
    state.receivedInvitations = [];
    state.performers = [];
    state.sports = [];
    state.theatres = [];
    state.special_events = [];
    state.availablePepperCash = 0;
    state.userIsVIPMember = false;
    state.userHasPromoCode = false;
    state.promoCodeForApply = null;
    state.prospect = false;
  },
  setError(state, error) {
    state.error = error;
  },

  storePerformersToSave(state, performers) {
    state.performersToSave = performers;
  },

  editPerformer(state, editedArtist) {
    const index = state.performers.findIndex(
      (post) => post.performer_id === editedArtist.performer_id
    );
    state.performers[index] = editedArtist;
  },

  storeInvitations(state, Invitations) {
    state.Invitations = Invitations;
  },

  storeReceivedInvitations(state, Invitations) {
    state.receivedInvitations = Invitations;
  },

  editInvitation(state, editedInvitation) {
    const invitaIndex = state.Invitations.findIndex(
      (invita) => invita.id === editedInvitation._id
    );
    state.Invitations[invitaIndex] = editedInvitation;
  },

  editEventIdFromInvitation(state, params) {
    const invitaIndex = state.Invitations.findIndex(
      (invita) => invita._id === params._id
    );
    state.Invitations[invitaIndex].event = params.event;
  },

  addFriendToInvitation(state, params) {
    const invitaIndex = state.Invitations.findIndex(
      (invita) => invita._id === params._id
    );
    state.Invitations[invitaIndex].invitedFriends.unshift(params.newFriend);
  },

  removeFriendToInvitation(state, params) {
    const invitaIndex = state.Invitations.findIndex(
      //params._id id de la invitación<---
      (invita) => invita._id === params._id
    );
    let invitedFriends = state.Invitations[invitaIndex].invitedFriends;
    const invitedIndex = invitedFriends.findIndex(
      //params.deleteFriend<---
      (invitee) => invitee.user.email === params.deleteFriend.email
    );

    invitedFriends.splice(invitedIndex, 1);
  },
};

export const actions = {
  /**
   * ==========================
   * @param {*} param0
   * @param {*} seleccionados
   * ==========================
   */
  setIsFavorite({ commit }, isFavorite) {
    commit("storeIsFavorite", isFavorite);
  },

  /**
   * ==========================
   * @param {*} param0
   * @param {*} seleccionados
   * ==========================
   */
  setSeleccionados({ commit, dispatch }, seleccionados) {
    commit("storeSeleccionados", seleccionados);
  },
  /**
   *
   * @param {*} param0
   */
  setPerformersToSave({ commit, dispatch }, performers) {
    commit("storePerformersToSave", performers);
  },
  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  setUserSeleccionados({ commit, dispatch }, seleccionados) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}db/user_web/${userId}/artists`, seleccionados)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  setUserSettings({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .put(
          `${baseUrl}db/user_web/${userId}/settings?${querystring.stringify(
            params
          )}`
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  setUserCoordinates({ commit, dispatch }, coordinates) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");

      let instance = axios.create();
      instance.defaults.headers.common = {};

      instance
        .put(`${baseUrl}db/user_web/${userId}/coordinates`, coordinates)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  setUserSports({ commit, dispatch }, seleccionados) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}db/user_web/${userId}/sports`, seleccionados)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  setUserPerformers({ commit, dispatch }, seleccionados) {
    const userId = localStorage.getItem("userId");

    return new Promise((resolve, reject) => {
      if (!userId) {
        resolve({});
        return;
      }

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}db/user_web/${userId}/performers`, seleccionados)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  async getEventBriteEventsFaves({ commit }) {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      return;
    }

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;
    let response = {};
    try {
      response = await instance.get(
        `${baseUrl}db/user_web/${userId}/event-brite-faves`
      );
    } catch (error) {
      console.log(error);
    }

    commit("event_brite_events", response.data.events);
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  async saveEventBriteEvent({ commit, dispatch }, event) {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      return;
    }

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;
    let response = {};
    try {
      response = await instance.put(
        `${baseUrl}db/user_web/${userId}/event-brite-faves`,
        event
      );
    } catch (error) {
      console.log(error);
    }

    commit("event_brite_events", response.data.events);
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  async deleteEventBriteEvent({ commit, dispatch }, delete_id) {
    const userId = localStorage.getItem("userId");
    if (!userId) {
      return;
    }

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;
    let response = {};
    try {
      response = await instance.delete(
        `${baseUrl}db/user_web/${userId}/event-brite-faves/${delete_id}`
      );
    } catch (error) {
      console.log(error);
    }

    commit("event_brite_events", response.data.events);
  },

  /**
   *
   * @param {*} param0
   * @param {*} seleccionados
   */
  deleteUserSeleccionados({ commit, dispatch }, seleccionados) {
    return new Promise((resolve, reject) => {
      const userId = localStorage.getItem("userId");

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}db/user_web/${userId}/artists-delete`, seleccionados)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} error
   * @description managment signin error function
   */
  error({ commit }, error) {
    commit("setError", error);
  },

  /**
   * ====================
   * @param {*} context
   * @param {*} payload
   * @description
   * ====================
   */
  login(context, payload) {},
  /**
   * =============================
   * @param {*} param0
   * @param {*} expirationTime
   * =============================
   */
  setLogoutTimer({ commit }, expirationTime) {
    setTimeout(() => {
      //los commits son para las mutations
      commit("clearAuthData");
    }, expirationTime * 1000);
  },
  /**
   * ========================================
   * @param {*} param0
   * @param {*} authData
   * @description Alta de usuario
   * ========================================
   */
  async signup({ commit, dispatch, rootState, state }, authData) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;

    let response = null;
    try {
      response = await instance.post(`${baseUrl}auth/register`, authData);
    } catch (error) {
      commit("clearAuthData");
      return null;
    }
    if (!response) {
      commit("clearAuthData");
      return null;
    }
    if (!response.data.access_token || !response.data.id) {
      commit("clearAuthData");
      return null;
    }

    commit("authUser", {
      token: response.data.access_token,
      userId: response.data.id,
    });

    dispatch("fetchUser", {
      idToken: response.data.access_token,
      userId: response.data.id,
    });

    const now = new Date();
    const expirationDate = now.getTime() + response.data.expiresIn * 1000;

    let imgSrc = "/img/home/profile.svg";
    if (response.data.imgSrc) {
      imgSrc = response.data.imgSrc;
    }

    commit("storeImgSrc", imgSrc);
    commit("storeUser", response.data);

    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("expirationDate", expirationDate);
    localStorage.setItem("userId", response.data.id);

    Cookie.set("token", response.data.access_token);
    Cookie.set("expirationDate", expirationDate);
    Cookie.set("userId", response.data.id);

    if (authData.prospect) {
      localStorage.setItem("prospect", authData.prospect);
      Cookie.set("prospect", authData.prospect);
      commit("prospect",  authData.prospect)
    }else{
      localStorage.removeItem("prospect");
      Cookie.remove("prospect");
      commit("prospect",  false)
    }

    dispatch("setLogoutTimer", response.data.expiresIn);

    if (state.signinGetReadyToAddPromo == true) {
      if (rootState.checkout.activePromoCode) {
        console.log(rootState.checkout.activePromoCode.code);
        if (rootState.checkout.activePromoCode.code) {
          await dispatch("addNewPromoToUser", {
            code: rootState.checkout.activePromoCode.code,
          });
        } else {
          console.log(`!rootState.checkout.activePromoCode.code`);
        }
      } else {
        console.log(`!rootState.checkout.activePromoCode`);
      }
    } else {
      console.log(`!state.signinGetReadyToAddPromo`);
    }

    return {
      token: response.data.access_token,
      userId: response.data.id,
    };
  },
  /**
   * ======================================
   * @param {*} param0
   * @param {*} authData
   * @description Login de Usuario
   * ======================================
   */
  async signin({ commit, dispatch, rootState }, authData) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;
    let response = {};
    try {
      response = await instance.post(`${baseUrl}auth/login`, authData);
    } catch (error) {}

    if (!response.data.access_token || !response.data.id) {
      commit("clearAuthData");
      return {};
    }
    commit("authUser", {
      token: response.data.access_token,
      userId: response.data.id,
    });

    dispatch("fetchUser", {
      idToken: response.data.access_token,
      userId: response.data.id,
    });

    let imgSrc = "/img/home/profile.svg";
    if (response.data.imgSrc) {
      imgSrc = response.data.imgSrc;
    }
    commit("storeImgSrc", imgSrc);

    commit("storeUser", response.data);

    const now = new Date();
    const expirationDate = now.getTime() + response.data.expiresIn * 1000;

    localStorage.setItem("token", response.data.access_token);
    localStorage.setItem("expirationDate", expirationDate);
    localStorage.setItem("userId", response.data.id);

    Cookie.set("token", response.data.access_token);
    Cookie.set("expirationDate", expirationDate);
    Cookie.set("userId", response.data.id);

    dispatch("setLogoutTimer", response.data.expiresIn);

    /*if (rootState.checkout.activePromoCode) {
      if (rootState.checkout.activePromoCode.code) {
        await dispatch("addNewPromoToUser", {
          code: rootState.checkout.activePromoCode.code,
        });
      }
    }*/

   
    localStorage.removeItem("prospect");
    Cookie.remove("prospect");
    commit("prospect",  false)
    

    await dispatch("verifyUserPromo");

    return response;
  },
  /**
   *
   * @param {*} param0
   * @param {*} response
   *
   */
  async signinByRecoveryPassword({ commit, dispatch }, response) {
     
      commit("authUser", {
        token: response.data.access_token,
        userId: response.data.id,
      });

      let imgSrc = "/img/home/profile.svg";
      if (response.data.imgSrc) {
        imgSrc = response.data.imgSrc;
      }
      commit("storeImgSrc", imgSrc);

      commit("storeUser", response.data);

      const now = new Date();
      const expirationDate = new Date(
        now.getTime() + response.data.expiresIn * 1000
      );
      localStorage.setItem("token", response.data.access_token);
      localStorage.setItem("expirationDate", expirationDate);
      localStorage.setItem("userId", response.data.id);

      dispatch("setLogoutTimer", response.data.expiresIn);
      await dispatch("verifyUserPromo");

      return {
        message: "Done! ",
      } 
    
  },
  /**
   * ============================
   * @param {*} param0
   * @description tryAutoSignin
   * ============================
   */
  async tryAutoSignin({ commit, dispatch }, req) {
    let token = undefined;
    let expirationDate = undefined;
    let userId = undefined;
    let prospect = undefined

    let termine = {
      termine: "Ok",
    };
    if (req != undefined) {
      if (req.headers.cookie) {
        let stringCookies = req.headers.cookie.split(";");
        if (stringCookies != undefined) {
          let stringToken = stringCookies.find((c) =>
            c.trim().startsWith("token=")
          );
          if (stringToken != undefined) {
            token = stringToken.split("=")[1];
          }
          /**
           *
           *
           */
          let stringUserId = stringCookies.find((c) =>
            c.trim().startsWith("userId=")
          );
          if (stringUserId != undefined) {
            userId = stringUserId.split("=")[1];
          }

          /**
           *
           *
           */
          let stringProspect = stringCookies.find((c) =>
            c.trim().startsWith("prospect=")
          );
          if (stringProspect != undefined) {
            prospect = stringProspect.split("=")[1];
          }
          if(prospect == true || prospect == "true" ){
            dispatch("logout");
            return termine;
          }

          /**
           *
           *
           */
          let stringExpirationDate = stringCookies.find((c) =>
            c.trim().startsWith("expirationDate=")
          );
          if (stringExpirationDate != undefined) {
            expirationDate = stringExpirationDate.split("=")[1];
          }
          const now = new Date();

          if (now.getTime() >= +expirationDate) {
            dispatch("logout");

            return termine;
          } else {
          }
        }
      }

      if (token && userId) {
        await dispatch("fetchUser", {
          idToken: token,
          userId: userId,
        });

        return termine;
      } else {
        return termine;
      }
    } else {
      if (process.browser) {
        token = localStorage.getItem("token");
        if (!token) {
          return termine;
        }
        expirationDate = localStorage.getItem("expirationDate");
        const now = new Date();
        if (now.getTime() >= +expirationDate) {
          dispatch("logout");
          return termine; //invalid token
        }
        userId = localStorage.getItem("userId");

       
        prospect = localStorage?.getItem("prospect");
        if(prospect == true || prospect == "true" ){
          dispatch("logout");
          return termine; 
        }

        if (token && userId) {
          dispatch("fetchUser", {
            idToken: token,
            userId: userId,
          }).then(() => {
            return termine;
          });
        } else {
          return termine;
        }
      }
    }
  },

  /**
   *
   * @param {*} param0
   * @description consulta todoos los datos del usuario y los guarda en el store
   */
  async updateUser({ commit, state }) {
    if (!state.idToken) {
      return;
    } else {
    }

    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${state.idToken}`;

    let data = {};
    try {
      const res = await instance.get(`${baseUrl}api/user/${userId}`);
      data = res.data;
      commit("storeUser", data.user);
    } catch (error) {
      commit("clearAuthData");
    }

    return data;
  },

  /**
   *
   *
   *
   * @param {*} param0
   * @description actualiza el promo code del  usuario
   */
  async updateUserPromoCode({ commit, state }, promo_code) {
    if (!state.idToken) {
      return;
    } else {
    }

    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${state.idToken}`;

    let data = {};
    const res = await instance.put(
      `${baseUrl}api/user/${userId}/promo_code`,
      promo_code
    );
    data = res.data;
    return data;
  },

  /**
   *
   *
   *
   * @param {*} promo_code
   * @description agrega un nuevo promo al usuario
   */
  async addNewPromoToUser({ commit, state, dispatch }, promo_code) {
    console.log(`user/addNewPromoToUser`);
    if (!state.idToken) {
      return;
    } else {
    }

    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${state.idToken}`;
    let res = {};
    try {
      res = await instance.put(
        `${baseUrl}api/user/${userId}/addnewpromo`,
        promo_code
      );
    } catch (error) {}

    const data = res?.data;
    await dispatch("verifyUserPromo");

    return data;
  },

  async getMembership({ commit, state }, params) {
    if (!state.idToken) {
      return null;
    }

    const userId = state.userId;
    const token = state.idToken;

    commit("userHasPromoCode", false);

    let userMemberShipResponse = null;
    try {
      userMemberShipResponse = await usersApi.getMembership(userId, token);
    } catch (e) {
      userMemberShipResponse = null;
    }

    if (userMemberShipResponse == null) {
      if (userMemberShipResponse.data == null) {
        return null;
      }
    }

     
    let dataMemberShip = userMemberShipResponse.data;
    if (
      dataMemberShip.discountValue > 0 &&
      dataMemberShip.isPercentage == true
    ) {

      let promoResponseBD = {}

      let instance = axios.create();
      instance.defaults.headers.common = {}

      let bodyPromo = {
        code: dataMemberShip.code
      };
    

      
      console.log(bodyPromo )
      try{
        promoResponseBD = await instance.post(`${baseUrl}checkout/get_promo_bd`, bodyPromo)
      }catch{
        //commit("activePromoCode", null);
        commit("checkout/activePromoCode",  null , { root: true });
        return
      }
    
      console.log(promoResponseBD )
      
      commit("userHasPromoCode", true);
      
      commit("storeUser", { ...state.user, promo_code: promoResponseBD.data });
     
      

      commit("checkout/promoAddedFromModal", true, { root: true });
      
      //commit("checkout/activePromoCode",  promoResponseBD.data, { root: true }); esta linea provocó un ciclado 
      
      commit("signinGetReadyToAddPromo", false);

      console.log(state.user.promo_code); 
    }

    return userMemberShipResponse;
  },

  async verifyUserPromo({ commit, state, dispatch }, params) {
    if (state.isAutenticated == false) {
      return;
    }
    let data = await dispatch("updateUser");
    if (data == undefined || data.user == undefined) {
      return;
    }
    let user = state.user;
    await dispatch("getMembership");

    if (state.userHasPromoCode) {
      let promoName = user.promo_code.name;
      if (!promoName) {
        promoName = user.promo_code.code;
      }
      commit(
        "promoCodeForApply",
        `${promoName?.toUpperCase()} SALE Discounted fees will apply at checkout.`
      );

      commit("userPromoAdded", true, { root: true });
    } else {
      commit("promoCodeForApply", null);
    }
  },

  /**
   *
   *
   *
   * @param {*} param0
   * @description actualiza el promo code del  usuario
   */
  async updateUserPromoCodeApplied({ commit, state }, applied) {
    if (!state.idToken) {
      return;
    } else {
    }

    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${state.idToken}`;

    let data = {};
    let res = await instance.put(
      `${baseUrl}api/user/${userId}/promo_code_applied`,
      applied
    );
    data = res.data;

    return data;
  },

  /**
   *
   * @param {*} param0
   * @description consulta todoos los datos del usuario y los guarda en el store
   */
  async refreshTraking({ commit, dispatch }, req) {
    await this.tryAutoSignin();
    dispatch("myArtists").then((artists) => {
      commit("storePerformers", artists);
    });

    dispatch("mySports").then((sports) => {
      commit("storeSports", sports);
    });

    dispatch("myTheatre").then((theatres) => {
      commit("storeTheatres", theatres);
    });

    dispatch("mySpecialEvents").then((special_events) => {
      commit("storeSpecial_events", special_events);
    });
  },
  /**
   *
   * @param {*} param0
   * @description consulta todoos los datos del usuario y los guarda en el store
   */
  async fetchUser({ commit, dispatch }, params) {
    if (params == undefined) {
      return;
    }
    if (!params.userId) {
      return;
    }

    //const userId = localStorage.getItem("userId");

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${params.idToken}`;

    let data = {
      user: {},
    };
    try {
      let res = await instance.get(`${baseUrl}api/user/${params.userId}`);
      data = res.data;
    } catch (error) {
      return;
    }

    commit("userIsVIPMember", false);
    if (!data.user) {
      return;
    }

    if (data.user.membership_code)
      if (data.user.membership_code.value)
        if (
          data.user.membership_code.value >= 10 &&
          data.user.membership_code.code.length >= 4
        ) {
          if (data.user.membership_code.expiration_date) {
            const now = new Date();
            let enddate = new Date(data.user.membership_code.expiration_date);
            if (enddate >= now) {
              commit("userIsVIPMember", true);
            }
          }
        }
    commit("storeUser", data.user);
    console.log("data.user");
    console.log(data.user);
    /*commit("storeIdToken",
        params.idToken
        
      );*/

    commit("authUser", {
      token: params.idToken,
      userId: params.userId,
    });

    let imgSrc = "/img/home/profile.svg";
    if (data.user.imgSrc) {
      imgSrc = data.user.imgSrc;
    }
    commit("storeImgSrc", imgSrc);
    return data;
  },

  /**
   * =======================
   * @param {*} param0
   * @description myArtists
   * =======================
   *
   */
  async myArtists({ commit, state }) {
    let artists = [];

    if (!state.idToken) {
      return artists;
    }
    const userId = state.userId; //localStorage.getItem("userId");

    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;

    try {
      let res = await instance.get(`${baseUrl}db/user_web/${userId}/artists`);

      artists = res.data;
    } catch (error) {
      return [];
    }

    for (let i = 0; i < artists.length; i++) {
      artists[i].activado = true;
    }
    //commit("storePerformers", artists);
    return artists;
  },

  /**
   * =======================
   * @param {*} param0
   * @description myArtists
   * =======================
   *
   */
  async mySports({ commit, state }) {
    let sports = [];
    if (!state.idToken) {
      return [];
    }
    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;

    try {
      let res = await instance.get(`${baseUrl}db/user_web/${userId}/sports`);
      sports = res.data;
    } catch (error) {}

    for (let i = 0; i < sports.length; i++) {
      sports[i].activado = true;
    }
    //commit("storeSports", sports);
    return sports;
  },

  /**
   * =======================
   * @param {*} param0
   * @description myArtists
   * =======================
   *
   */
  async myTheatre({ commit, state }) {
    let teathre = [];
    if (!state.idToken) {
      return [];
    }
    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;

    try {
      let res = await instance.get(`${baseUrl}db/user_web/${userId}/theatre`);
      teathre = res.data;
    } catch (error) {}

    for (let i = 0; i < teathre.length; i++) {
      teathre[i].activado = true;
    }
    //commit("storeTheatres", teathre);
    return teathre;
  },

  /**
   * =======================
   * @param {*} param0
   * @description myArtists
   * =======================
   *
   */
  async mySpecialEvents({ commit, state }) {
    let special_events = [];
    if (!state.idToken) {
      return special_events;
    }
    const userId = state.userId; //localStorage.getItem("userId");
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.headers.common[
      "authorization"
    ] = `Bearer ${configVars.API_REST_SECRET}`;

    try {
      let res = await instance.get(
        `${baseUrl}db/user_web/${userId}/special_events`
      );
      special_events = res.data;
    } catch (error) {}

    //commit("storePerformers", artists);
    for (let i = 0; i < special_events.length; i++) {
      special_events[i].activado = true;
    }
    //commit("storeSpecial_events", special_events);
    return special_events;
  },

  /**
   * ===================
   * @param {*} param0
   * @description logout
   * ===================
   */
  logout({ commit, dispatch, rootState }) {
    commit("clearAuthData");
    dispatch("checkout/resetCheckoutPromoCode", null, { root: true });
    dispatch("subscription/resetSubscriptionData", null, { root: true });

    localStorage.removeItem("token");
    localStorage.removeItem("expirationDate");
    localStorage.removeItem("userId");
    localStorage.removeItem("prospect");

    Cookie.remove("token");
    Cookie.remove("expirationDate");
    Cookie.remove("userId");
    Cookie.remove("prospect");
    commit("prospect",  false)

    let instance = axios.create();
    instance.defaults.headers.common = {};

    instance.post(`${baseUrl}auth/logout`).then((response) => {});
  },
  /**
   * ==================================
   * @param {*} param0
   * @param {*} email
   * @description recovery password
   * ==================================
   */
  recoveryPassword({ commit, dispatch }, email) {
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}auth/recovery`, {
          email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  /**
   * ==================================
   * @param {*} param0
   * @param {*} email
   * @description recovery password
   * ==================================
   */
  email_exists({ commit, dispatch }, email) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}auth/email_exists`, {
          email,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  /**
   * ==================================
   * @param {*} param0
   * @param {*} email
   * @description recovery password
   * ==================================
   */
  resetPassword({ commit, dispatch }, params) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}auth/reset_password`, params)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
  /**
   * ==================================
   * @param {*} param0
   * @param {*} email
   * @description recovery password
   * ==================================
   */
  verifiyTokenRecoveryPassword({ commit, dispatch }, token) {
    let instance = axios.create();
    instance.defaults.headers.common = {};
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common[
        "authorization"
      ] = `Bearer ${configVars.API_REST_SECRET}`;
      instance
        .post(`${baseUrl}auth/verify_token`, {
          token,
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  coordinatesToCity({ commit, dispatch }, params) {
    return new Promise((resolve, reject) => {
      if (params.lat && params.lon) {
        let instance = axios.create();
        instance.defaults.headers.common = {};
        instance.defaults.headers.common[
          "authorization"
        ] = `Bearer ${configVars.API_REST_SECRET}`;
        instance
          .get(
            `${baseUrl}go/maps/api/geocode/?latlng=${params.lat},${params.lon}`
          )
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
          });
      } else {
        let error = {
          message: `No se pasaron los parámetros lat y lon`,
        };
        reject(error);
      }
    });
  },
};
