

import moment from "moment";

const occurs_at_gte = (month) =>{

  let currentDate = moment();
  if(month){
    currentDate = moment().year(currentDate.year()).month(month).date(1)
  }
  //console.log(currentDate)
  let occurs_at_gteM = moment(currentDate, moment.ISO_8601).format();
  occurs_at_gteM = occurs_at_gteM.substring(0, occurs_at_gteM.length - 6);
  
  //return  moment();
  return occurs_at_gteM;


}


const formatZDate = (occurs_at) => {
 

  return moment(occurs_at).format("DD/MM/YY")

}


 const addMonthToday =  () => {
  let currentDate = moment();
  var fm = moment(currentDate).add(1, 'M');
  var fmEnd = moment(fm).endOf('month');
  let returnDate =  currentDate.date() != fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm;  
  returnDate  =  moment(returnDate).format("ll");

  return returnDate;
}



const yearsSelect = () => {
  let currentYear = moment().year()
  let years = []
  for(let i = 1; i <= 20; i++){

    years.push(   { value: currentYear, text: `${currentYear}` } )
    currentYear ++
  }
  return years
 
}

 
const months = ( )=> {

  let months_ = []
  for(let i = 1; i <= 12; i++){

    months_.push(   { value: i, text: `${i}` } )
  
  }

  return months_


 
}


export default {
  occurs_at_gte,
  formatZDate,
  addMonthToday,
  yearsSelect,
  months
};
