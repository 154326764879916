 

const blockScroll = (block =false)=> {
  if(block === true){
    document.getElementsByTagName("html")[0].setAttribute('style', 'overflow-y: hidden !important');
    /*document.getElementsByTagName("body")[0].setAttribute('style', 'position: fixed !important');*/
  }else{
   
    document.getElementsByTagName("html")[0].style.overflowY = "";
    document.getElementsByTagName("body")[0].style.position = ""
  }
}


export default {
  blockScroll
};
