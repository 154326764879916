export const state = () => ({
  toogleSignUp: false,
  signUpModePromo: false, 
  recoveryToogle: false,
  recoverySendEmail: false,
});

export const getters = {
  toogleSignUp(state) {
    return state.toogleSignUp;
  },
  signUpModePromo(state){
    return state.signUpModePromo
  },
  recoveryToogle(state){
    return state.recoveryToogle
  },
  recoverySendEmail(state){
    return  state.recoverySendEmail
  }
};

export const mutations = {
  toogleSignUp(state, toogleSignUp) {
    state.toogleSignUp = toogleSignUp;
  },

  signUpModePromo(state, signUpModePromo){
    state.signUpModePromo = signUpModePromo
  },
  recoveryToogle(state, recoveryToogle){
    state.recoveryToogle = recoveryToogle
  },
  recoverySendEmail(state, recoverySendEmail){
    state.recoverySendEmail = recoverySendEmail
  }
};
