export const state = () => ({
    customer: {
      id: null,
    },
    payment_type: '',
    subscription: {
        planId: "",
        price: 0,
        createdAt: "",
        currentBillingCycle: 0,
        billingDayOfMonth: 0,
        nextBillingDate: "",
        paymentMethodToken:  ""
    },
    paymentMethods: [],
    creditCards:[],
    paypals: [],
    currentPaymentMethod: {
      token: '',
      bin: null,
      cardType : null ,
      expirationMonth: null,
      expirationYear: null,
      expired: null,
      imageUrl: '',
      last4: null,
      maskedNumber: null,
      email: null


    },
    currentCreditCardToken: '',
    currentPaypalToken: '',
    currentPaymentMethodToken: '',


    threeDSecureParameters: {
      amount: '',
      email: '',
      billingAddress : {  
        givenName: '',  
        surname: '', 
        phoneNumber: '',
        streetAddress: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCodeAlpha2: ''
      }
    },
    validPaymentMethod: false ,
    transactions: [],
    viewTransaction: {}
    
});


export const getters = {
    validPaymentMethod(state){
      return state.validPaymentMethod
    },

    payment_type(state){
      return state.payment_type
    },
    customer(state) {
        return state.customer;
    },

    subscription(state) {
        return state.subscription;
    },

    paymentMethods(state) {
      return state.paymentMethods;
    },
    
    creditCards(state) {
      return state.creditCards;
    },

    transactions(state){
      return state.transactions
    },

    viewTransaction(state){
      return state.viewTransaction
    },

    paypals(state) {
      return state.paypals;
    },

    currentPaymentMethod(state) {
        return state.currentPaymentMethod;
    },

    currentCreditCardToken(state ){
      return state.currentCreditCardToken;
    },

    currentPaypalToken(state){
      return state.currentPaypalToken
    },

    currentPaymentMethodToken(state){
      return state.currentPaymentMethodToken
    },

    threeDSecureParameters(state){
      return state.threeDSecureParameters
    }

};


export const mutations = {

    validPaymentMethod(state, validPaymentMethod ){
      state.validPaymentMethod = validPaymentMethod
    },
    
    customer(state, customer) {
      state.customer = customer
    },
    
    payment_type(state, payment_type){
      state.payment_type = payment_type
    },

    subscription(state, subscription) {
      state.subscription = subscription
    },

   

    paymentMethods(state, paymentMethods){
      state.paymentMethods = paymentMethods
    },

    creditCards(state, creditCards){
      state.creditCards = creditCards
    },

    transactions(state, transactions){
      state.transactions = transactions
    },

    viewTransaction(state, viewTransaction){
      state.viewTransaction = viewTransaction
    },

    paypals(state, paypals){
      state.paypals = paypals
    },


    currentPaymentMethod(state, currentPaymentMethod){
     
      state.currentPaymentMethod = currentPaymentMethod   //currentPaymentMethod
    },

    currentCreditCardToken(state, currentCreditCardToken ){
      state.currentCreditCardToken = currentCreditCardToken
    },
    
    currentPaypalToken(state, currentPaypalToken){
      state.currentPaypalToken = currentPaypalToken
    },

    currentPaymentMethodToken(state, currentPaymentMethodToken){
      state.currentPaymentMethodToken = currentPaymentMethodToken
    },


    addNewPaymentMethod(state, newPaymenMethod) {
      let index = state.paymentMethods.findIndex(item => item.email == newPaymenMethod.email)
      if(index < 0 ){
        state.paymentMethods =  [newPaymenMethod, ...state.paymentMethods]   
      }
        
    },

    
    deletePaymentMethod(state, token) {
      state.paymentMethods =   state.paymentMethods.filter( item => item.token != token  )
    },

    deleteCreditCard(state, token) {
      state.creditCards =   state.creditCards.filter( item => item.token != token  )
    },

    deletePaypal(state, token) {
      state.paypals =   state.paypals.filter( item => item.token != token  )
    },
    



    addNewCreditCard(state, newCreditCard) {
      state.creditCards =  [newCreditCard, ...state.creditCards]     
    },

  

    addNewPaypal (state, paypal){
      state.paypals = [ paypal, ...state.paypals  ]
    },

    threeDSecureParameters (state, threeDSecureParameters){
      state.threeDSecureParameters = threeDSecureParameters
    }



  

};

export const actions = {
  resetSubscriptionData({ commit, dispatch, rootState, state  }) {
    commit("customer", {id: null })
    commit("payment_type", '')
    commit("subscription", {
      planId: "",
      price: 0,
      createdAt: "",
      currentBillingCycle: 0,
      billingDayOfMonth: 0,
      nextBillingDate: "",
      paymentMethodToken:  ""
    })
    commit("currentPaymentMethod", {
      token: '',
      bin: null,
      cardType : null ,
      expirationMonth: null,
      expirationYear: null,
      expired: null,
      imageUrl: '',
      last4: null,
      maskedNumber: null,
      email: null 
    })

    commit("paymentMethods", [])
    commit("creditCards", [])
    commit("paypals", [])
    commit("transactions", [])
    commit("viewTransaction", {})
    
    commit("currentCreditCardToken", '')
    commit("currentPaypalToken", '')
    commit("currentPaymentMethodToken", '')
    commit("threeDSecureParameters", {
      amount: '',
      email: '',
      billingAddress : {  
        givenName: '',  
        surname: '', 
        phoneNumber: '',
        streetAddress: '',
        locality: '',
        region: '',
        postalCode: '',
        countryCodeAlpha2: ''
      }
    })
    


   
  
 

  },
}


