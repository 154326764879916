import configVars from '@/config/config_vars';
let appBaseUrl = configVars.appBaseUrl;

import axios from 'axios';

import querystring from 'querystring';

var getCategoryByName = async (name, exact = 'false') => {
 
    let searchCategoryByName = `${appBaseUrl}db/category/${name}?exact=${exact}`;
 
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let response = {
      data: []
    }
    try{
    response =  await instance
      .get(`${searchCategoryByName}`)
    }catch(error){
      console.log(`Error getCategoryByName ${error} `)
    }

    return response

   
};
var getMusicCategories = () => {
  return new Promise((resolve, reject) => {
    let searchCategoryByName = `${appBaseUrl}db/music/`;
  
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    instance
      .get(`${searchCategoryByName}`)
      .then(response => {
        resolve(response);
      })
      .catch(error => {
      
      });
  });
};

var searchCategoriesByParentId = async (id) => {
 
    let searchCategoryByName = `${appBaseUrl}db/categories/parent/${id}`;
    
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;

    let response = {
      data: []
    }
    try{
      response = await  instance.get(`${searchCategoryByName}`)
    }catch(error){
      console.log(`Error searchCategoriesByParentId ${error} `)
    }
   
    return response
 
  
};

var searchCategoriesById = async (id) => {
 
    let searchCategoryByName = `${appBaseUrl}db/categories/?id=${id}`;
  
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.timeout = 9000;

    let response = {
      data: []
    }
    try{
      response = await instance.get(`${searchCategoryByName}`)
    }catch(error){
      console.log(`Error searchCategoriesById ${error} `)
    }

    return response
  
   
};



var getCategoriesByCategoriesId = async (categories) => {
 
    let searchCategoriesByID = `${appBaseUrl}db/categories_in/${categories}`;
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;

    let response = {
      data: []
    }
    try {
      response = await instance.get(`${searchCategoriesByID}`)
    } catch (error) {
      console.log(`Error getCategoriesByCategoriesId ${error} `)
    }
   
    return response 
      
  
};




export default {
  getCategoryByName,
  getMusicCategories,
  searchCategoriesByParentId,
  searchCategoriesById,
  getCategoriesByCategoriesId
};
