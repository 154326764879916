import apiAuth from '@/api/auth'
export default function ({
  isServer,
  store,
  req
}) {

  let payload = {
    "pepper": {
      "identificacionDPagina": "91507758",
      "nombreDePagina": "Pepper-Bot-Web-Page"
    }
  }

  return apiAuth.create_auth_token(payload).then((res) => {
   
    store.commit("storeAuthToken", res.token)
  })




}
