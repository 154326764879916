const middleware = {}

middleware['app_redirect'] = require('../middleware/app_redirect.js')
middleware['app_redirect'] = middleware['app_redirect'].default || middleware['app_redirect']

middleware['auth'] = require('../middleware/auth.js')
middleware['auth'] = middleware['auth'].default || middleware['auth']

middleware['check-auth'] = require('../middleware/check-auth.js')
middleware['check-auth'] = middleware['check-auth'].default || middleware['check-auth']

middleware['check-sender'] = require('../middleware/check-sender.js')
middleware['check-sender'] = middleware['check-sender'].default || middleware['check-sender']

middleware['get_ip'] = require('../middleware/get_ip.js')
middleware['get_ip'] = middleware['get_ip'].default || middleware['get_ip']

middleware['log'] = require('../middleware/log.js')
middleware['log'] = middleware['log'].default || middleware['log']

middleware['pepper_auth'] = require('../middleware/pepper_auth.js')
middleware['pepper_auth'] = middleware['pepper_auth'].default || middleware['pepper_auth']

middleware['setZIndex'] = require('../middleware/setZIndex.js')
middleware['setZIndex'] = middleware['setZIndex'].default || middleware['setZIndex']

middleware['subscription'] = require('../middleware/subscription.js')
middleware['subscription'] = middleware['subscription'].default || middleware['subscription']

export default middleware
