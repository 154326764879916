
export default {
  data() {
    return {
      displaySidenav: false
    };
  },
  mounted() {
    if (process.browser) {
      var vm = this;
      var mqls = [
        window.matchMedia("screen and (max-width: 600px)"),
        window.matchMedia(
          "screen and (min-width: 601px) and (max-width: 768px)"
        ),
        window.matchMedia(
          "screen and (min-width: 769px) and (max-width: 991px)"
        ),
        window.matchMedia(
          "screen and (min-width: 992px) and (max-width: 1199px)"
        ),
        window.matchMedia("screen and (min-width: 1200px) ")
      ];

      function mediaqueryresponse(mql) {
        for (let i = 0; i < mqls.length; i++) {
          if (mqls[i].matches) {
           
            vm.$store.commit("change_media_match", i);
          }
        }
      }
      for (var i = 0; i < mqls.length; i++) {
        mediaqueryresponse(mqls[i]);
        mqls[i].addListener(mediaqueryresponse);
      }
    }
  }
};
