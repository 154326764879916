import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";



var searchCities = async (params) => {
 
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let searchCitiesQuery = `${appBaseUrl}db/cities/?${querystring.stringify(
      params
    )}`;
    let data = {

    }
    try {
      let response = await instance.get(`${searchCitiesQuery}`)
      data = response.data

    } catch (error) {
      
    }

    return data
 
  
}


//http://localhost:3069/db/cities_in/["new-orleans","san-francisco-ca","new-york-ny","las-vegas-nv","anaheim-ca"]

var getCitiesBySlugs = async (cities_slugs) => {
 
    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    let searchCitiesBySlugs = `${appBaseUrl}db/cities_in/${cities_slugs}`


    let data  = {

    }

    try {
      let response = await instance.get(`${searchCitiesBySlugs}`)
      data = response.data
    } catch (error) {
      
    }



    return data
 
  
}

export default {
  searchCities,
  getCitiesBySlugs
}
