const fbVersion = 'v20.0'
function facebook(loadChat = false, executeAfterLoadFBSDK = () => {
  FB?.CustomerChat?.hide();
}) {
  //console.log('pasé x aki!')
  if (loadChat) {
    var chatbox = document.getElementById('fb-customer-chat');
    chatbox.setAttribute("page_id", "1593266137651203");
    chatbox.setAttribute("attribution", "biz_inbox");
  }



  if (window.fbAsyncInit) {
    console.log('exists window.fbAsyncInit')
    console.log(window.fbAsyncInit)
    executeAfterLoadFBSDK();
    return
  }
  console.log('window.fbAsyncInit did not exist')

  window.fbAsyncInit = function () {
    console.log()
    FB.init({
      appId: `1580071112297891`,
      cookie: true, // enable cookies to allow the server to access the session
      xfbml: true, // parse social plugins on this page
      version: fbVersion // use graph api version 2.8
    });
    executeAfterLoadFBSDK()

  };

  (function (d, s, id) {
    var js;
    var fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    //js.src = '//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.9'
    //js.src = "https://connect.facebook.net/en_US/sdk.js";
    js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"//"https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
    fjs.parentNode.insertBefore(js, fjs);

  })(document, "script", "facebook-jssdk");

}

/*function facebook (loadChat = false, callback) {
  if(!window.fbApiInit) {
      setTimeout(function() {loadFBSDK(loadChat, callback);   }, 50);
  } else {
      if(callback) {

        console.log('execute  callbak after init')
          callback();
      }
  }
}*/


const loginWithFacebookRedirect = (context, state) => {
  const authPathComplement = '/auth/facebook';
  const APP_FRONT_URL = context.$config.APP_FRONT_URL;
  const authFacebookPath = `${APP_FRONT_URL}${authPathComplement}`;
  const appId = '1580071112297891';
  const redirectUri = authFacebookPath;
  const scopes = 'email,public_profile';
  const fbAuthUrl = `https://www.facebook.com/${fbVersion}/dialog/oauth?client_id=${appId}&redirect_uri=${redirectUri}&state=${state}&scope=${scopes}`;

  window.location.href = fbAuthUrl;
};


export default {
  facebook,
  loginWithFacebookRedirect
}
