import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";










/**
 * ============================
 * @param {*} param0
 * @param {*} params
 * @description actionGetEventTevoQuery
 * ============================
 */
var actionGetEventTevoQuery = async (params) => {
  let searchEventQuery = `${appBaseUrl}tevo/events/query?${querystring.stringify(params)}`;

  //searchEventQuery = `${appBaseUrl}tevo/events/query?category_id=1`;

 
    //let instance = axios.create();
    //instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;

    let data = []
    try{
      let response = await axios.get(searchEventQuery)
      data = response.data
    }catch(error){
      console.error(`Error  actionGetEventTevoQuery: ${error}`)
    }

    return data
    
 
}

/**
 * ============================
 * @param {*} param0
 * @param {*} params
 * @description actionGetEventTevoQuery
 * ============================
 */
 const getTevoEventsFromDb = async (params) => {
  let searchEventQuery = `${appBaseUrl}v1/tevo/events-db?${querystring.stringify(params)}`;

  //searchEventQuery = `${appBaseUrl}tevo/events/query?category_id=1`;

 
    //let instance = axios.create();
    //instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;

    let data = []
    try{
      let response = await axios.get(searchEventQuery)
      data = response.data
    }catch(error){
      console.error(`Error  getTevoEventsFromDb: ${error}`)
    }

    return data
    
 
}



/**
 * ============================
 * @param {*} param0
 * @param {*} params
 * @description actionGetEventTevoQuery
 * ============================
 */
var actionGetEventTevoQuery2 = async (params) => {
  let searchEventQuery = `${appBaseUrl}tevo/events/query2/?${querystring.stringify(
      params
    )}`;

 
    let instance = axios.create();
    instance.defaults.headers.common = {};
    instance.defaults.timeout = 9000;
    let data = []
    try{
      let response = await instance.get(searchEventQuery)
      data = response.data
    }catch(error){
      if (error.code === 'ECONNABORTED') {
        console.log('Error actionGetEventTevoQuery2: The request time out.');
      } else {
        console.log(`Error actionGetEventTevoQuery2: ${error.message}`);
      }
    }

    return data
  
}





  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchEventById
   * ============================
   */
  var searchEventById = async ( event_id) => {
    let searchEventByIdQ = `${appBaseUrl}tevo/event/${event_id}`;
    
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.timeout = 9000;

      let response = {

      }
      try{
        response = await instance.get(`${searchEventByIdQ}`)
      }catch(error){
        console.error(`Error  searchEventById: ${error}`)
      }
      return response 
  
    
  }


    /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchTicketGroups
   * ============================
   */
  var searchTicketGroups = async ( event_id)=> {
    let searchTicketGroupQ = `${appBaseUrl}tevo/ticket_groups/${event_id}`;
   
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.timeout = 20000;

      let response = {
        data: []
      }

      try {
        response =  await instance.get(`${searchTicketGroupQ}`)
      } catch (error) {
        console.error(`Error  searchTicketGroups: ${error}`)
      }
     


      return response 
 
    
  }




var loadingClassF = events => {
  let categoria_padre = 1;
  let loadingClass = "loading_sport";
  let loadingImage = "/img/no-image/no_image_sport.jpg"
  if (events.length > 0 && events[0] != undefined) {
    if (events[0].category) {
      if (events[0].category.id) {
        
        if (events[0].category.parent) {
     
          if (events[0].category.parent.parent) {
           
            if (events[0].category.parent.parent.id) {
           
              categoria_padre = events[0].category.parent.parent.id;
            } else {
            
              categoria_padre = events[0].category.parent.id;
            }
          } else {
       
            categoria_padre = events[0].category.parent.id;
          }
        } else {
      
          categoria_padre = events[0].category.id;
        }
      }
    }

    switch (categoria_padre) {
      case "1":
        {
          loadingClass = "loading_sport";
          loadingImage = "/img/no-image/no_image_sport.jpg"
        }
        break;
      case "54":
        {
          loadingClass = "loading_concert";
          loadingImage = "/img/no-image/no_image_concert.jpg"
        }
        break;
      case "68":
        {
          loadingClass = "loading_theatre";
          loadingImage = "/img/no-image/no_image_theater.jpg"
        }
        break;
      case "73":
        {
          loadingClass = "loading_special";
          loadingImage = "/img/no-image/no_image_special_event.jpg"
        }
        break;
      default:
        {
          loadingClass = "loading_sport";
          loadingImage = "/img/no-image/no_image_sport.jpg"
        }
        break;
    }
  }

  return {
    categoria_padre,
    loadingClass,
    loadingImage
  };
};


var loadingCSSClass = event => {
  let categoria_padre = 1;
  let loadingClass = "loading_sport";
  let loadingImage = "/img/no-image/no_image_sport.jpg"
 
    if (event.category) {
      if (event.category.id) {
        
        if (event.category.parent) {
     
          if (event.category.parent.parent) {
           
            if (event.category.parent.parent.id) {
           
              categoria_padre = event.category.parent.parent.id;
            } else {
            
              categoria_padre = event.category.parent.id;
            }
          } else {
       
            categoria_padre = event.category.parent.id;
          }
        } else {
      
          categoria_padre = event.category.id;
        }
      }
    }

    switch (categoria_padre) {
      case "1":
        {
          loadingClass = "loading_sport";
          loadingImage = "/img/no-image/no_image_sport.jpg"
        }
        break;
      case "54":
        {
          loadingClass = "loading_concert";
          loadingImage = "/img/no-image/no_image_concert.jpg"
        }
        break;
      case "68":
        {
          loadingClass = "loading_theatre";
          loadingImage = "/img/no-image/no_image_theater.jpg"
        }
        break;
      case "73":
        {
          loadingClass = "loading_special";
          loadingImage = "/img/no-image/no_image_special_event.jpg"
        }
        break;
      default:
        {
          loadingClass = "loading_sport";
          loadingImage = "/img/no-image/no_image_sport.jpg"
        }
        break;
    }
  

  return {
    categoria_padre,
    loadingClass,
    loadingImage
  };
};

 
export default {
  actionGetEventTevoQuery,
  actionGetEventTevoQuery2,
  loadingClassF,
  loadingCSSClass,
  searchEventById,
  searchTicketGroups,
  getTevoEventsFromDb
   
}
