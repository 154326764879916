import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=642d598f&scoped=true"
import script from "./Search.vue?vue&type=script&lang=js"
export * from "./Search.vue?vue&type=script&lang=js"
import style0 from "./Search.vue?vue&type=style&index=0&id=642d598f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "642d598f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SearchPerformer: require('/tmp/build_4ffedd68/components/Search/Performer.vue').default,SearchDotsLoading: require('/tmp/build_4ffedd68/components/Search/DotsLoading.vue').default})
