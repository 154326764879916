const  pushIfNew = (events, event) => {

    if(!event.performances)  {
      return
    }
    if(!event.performances.performer)  {
      return
    }
  
    for (let i = 0; i < events.length; i++) {

      
      if (
        events[i].performances.performer.id ==
        event.performances.performer.id
      ) {
         
        return;
      }
    }
 
    events.push(event);
  };


  const  pushIfNewEvent  = (events, event) => {

 
  
    for (let i = 0; i < events.length; i++) {

      
      if (
        events[i].id ==
        event.id
      ) {
         
        return;
      }
    }
 
    events.push(event);
  };




  export default {
    pushIfNew,
    pushIfNewEvent
  };

  