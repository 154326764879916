/* eslint-disable */

import globalAxios from "axios";
import configVars from "../config/config_vars";
const appBaseUrl = configVars.appBaseUrl;

import moment from "moment";
import qs from "qs";
import querystring from "querystring";

import axios from "axios";

const indexState = require('./index').state

function generateSortFn(prop, reverse) {
  return function (a, b) {
    if (a[prop] < b[prop]) return reverse ? 1 : -1;
    if (a[prop] > b[prop]) return reverse ? -1 : 1;
    return 0;
  };
}
export const state = () => ({
  eventsByPerformerName: [],
  NearmeConcerts: null
});

export const getters = {
  NearmeConcerts(state) {
    return state.NearmeConcerts;
  }
};

export const mutations = {
  storeNearmeConcerts(state, NearmeConcerts) {
    state.NearmeConcerts = NearmeConcerts;
  }
};

export const actions = {
  /**
   *
   * @param {*} param0
   * @param {*} params
   */
  setNearmeConcerts({
    commit,
    dispatch
  }, params) {
    commit("storeNearmeConcerts", params);
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description http://localhost:8888/tevo/performer/shakira/page/1/per_page/2
   */
  actionGetEventsByPerformerName({
    commit,
    dispatch
  }, params) {
    let searchEventsByPerformerName = `${appBaseUrl}tevo/performer/${
      params.name
    }/page/${params.page}/per_page/${params.per_page}`;

    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventsByPerformerName}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
        
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description http://localhost:8888/tevo/performer_tickets/shakira/page/1/per_page/2
   */
  searchTicketsEventsByPerformerName({
    commit,
    dispatch
  }, params) {
    let searchEventsByPerformerName = `${appBaseUrl}tevo/performer_tickets/${
      params.name
    }/page/${params.page}/per_page/${params.per_page}`;

    return new Promise((resolve, reject) => {

      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventsByPerformerName}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
         
        });
    });
  },

  /**
   *
   * @param {*} param0
   * @param {*} name
   * @description http://localhost:8888/tevo/category/54/lat/34.0522300/lon/-118.2436800/page/1/per_page/2
   *
   */
  actionGetEventsByCategoryAndLocation({
    commit,
    dispatch
  }, params) {
    let searchEventsByCategoryAndLocation = `${appBaseUrl}tevo/category/${
      params.category_id
    }/lat/${params.lat}/lon/${params.lon}/page/${params.page}/per_page/${
      params.per_page
    }`;
   
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventsByCategoryAndLocation}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
        
        });
    });
  },

  actionGetEventTicketMasterQuery({
    commit,
    dispatch
  }, query) {
    let searchEventTicketMasterQuery = `${appBaseUrl}tevo/master/query/?${query}`;
   
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventTicketMasterQuery}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          
        });
    });
  },
  actionGetEventDuringTheNext5DaysByLocation({
    commit,
    dispatch
  }, params) {
    let currentDate = moment();
    let occurs_at_gte = moment(currentDate, moment.ISO_8601).format();
    occurs_at_gte = occurs_at_gte.substring(0, occurs_at_gte.length - 6);
    let occurs_at_lte = currentDate.add(15, "d");
    occurs_at_lte = moment(occurs_at_lte, moment.ISO_8601).format();
    occurs_at_lte = occurs_at_lte.substring(0, occurs_at_lte.length - 6);


    let query = {
      lat: params.lat,
      lon: params.lon,
      //only_with_tickets: "all",
      "occurs_at.gte": occurs_at_gte,
      "occurs_at.lte": occurs_at_lte,
      only_with_available_tickets: true,
      //order_by: "events.occurs_at ASC",
      order_by: "events.popularity_score DESC",
      page: 1,
      per_page: 24,
      within: 25
    };

    /*let query = {
      lat: params.lat,
      lon: params.lon,
      page: params.page,
      per_page: params.per_page,
      "occurs_at.gte": occurs_at_gte,
      "occurs_at.lte": occurs_at_lte,
      // order_by: "performers.popularity_score DESC",
    };*/

    return new Promise((resolve, reject) => {
      dispatch("actionGetEventTevoQuery", query).then(response => {
        resolve(response);
      });
    });
  },
  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description actionGetEventTevoQuery
   * ============================
   */
  actionGetEventTevoQuery({
    commit,
    dispatch,
    rootState
  }, params) {
    let searchEventQuery = `${appBaseUrl}tevo/events/query/?${querystring.stringify(
      params
    )}`;

    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      //instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      instance.defaults.headers.common["authorization"] = `Bearer ${rootState.authToken}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventQuery}`)
        .then(response => {

          resolve(response);

          
        })
        .catch(error => {
          console.log(searchEventQuery)
          reject(`error-actionGetEventTevoQuery ${error}`);
        
        });
    });
  },

  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchTicketGroups
   * ============================
   */
  async searchTicketGroups({
    commit,
    dispatch
  }, params) {
    let searchTicketGroupQ = `${appBaseUrl}tevo/ticket_groups/${
      params.event_id
    }`;
    
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      let response = {}
      try{
        response = await instance.get(`${searchTicketGroupQ}`)
      }catch(error){

      }
       
      console.log(searchTicketGroupQ) //TODO: borrar
      return response 
  },

  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchTicketGroups
   * ============================
   */
  async searchTicketGroup({
    commit,
    dispatch
  }, params) {
    let searchTicketGroupQ = `${appBaseUrl}tevo/ticket_group/${
      params.ticket_group_id
    }`;
   
   
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      let response = {}
      try{
        response =  await instance.get(`${searchTicketGroupQ}`)
      }catch(error){

      }
      
      return response
   
   
  },
  /**
   * ============================
   * @param {*} param0
   * @param {*} params
   * @description searchEventById
   * ============================
   */
  searchEventById({
    commit,
    dispatch
  }, params) {
    let searchEventByIdQ = `${appBaseUrl}tevo/event/${params.event_id}`;
   
    return new Promise((resolve, reject) => {
      let instance = axios.create();
      instance.defaults.headers.common = {};
      instance.defaults.headers.common["authorization"] = `Bearer ${configVars.API_REST_SECRET}`;
      //instance.defaults.timeout = 9000;
      instance
        .get(`${searchEventByIdQ}`)
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          console.log(searchEventByIdQ )
          reject(`error-searchEventById ${error}`);
        });
    });
  }
};
