// mixins/chatBubble.js
import { mapGetters } from "vuex";
export default {
  created() {
    if (process.client) {
      if (window.$crisp) {
        $crisp.push(["do", "chat:hide"]);
      }
      if (window.$chatwoot) {
        console.log(` window.$chatwoot.toggle('close')`)
        window.$chatwoot.toggle('close')
        window.$chatwoot.toggle("close");

      }
      this.handleChatBubbleVisibility();
    }
   
  },
  computed: {
    ...mapGetters({

      toogleSignIn: "toogleSignIn",


    }),
  },
  watch: {
    '$route'() {
      if (process.client) {
        if (window.$chatwoot) {
          console.log(` window.$chatwoot.toggle('close')`)
          window.$chatwoot.toggle('close')
          window.$chatwoot.toggle("close");

        }
        if (window.$crisp) {
          $crisp.push(["do", "chat:hide"]);
        }
      }
      this.handleChatBubbleVisibility();
    },
    toogleSignIn(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.toggleBubbleVisibility('hide');
        } else {
          this.toggleBubbleVisibility('show');//TODO: Change hide by show
        }
      }
    }
  },
  methods: {
    shouldShowChat() {
      return this.$route.path.match(/^\/book\/[^\/]+\/[^\/]+\/[^\/]+$/) ||
        this.$route.path.match(/^\/checkout\/\d+$/);
    },
    handleChatBubbleVisibility() {


      if (this.$route.path.match(/^\/book\/[^\/]+\/[^\/]+\/[^\/]+$/)
        || this.$route.path.match(/^\/checkout\/\d+$/)
      ) {
        console.log('show chat bubble');
        this.toggleBubbleVisibility('show');
      } else {
        console.log('hide chat bubble');
        this.toggleBubbleVisibility('hide');
      }
    },
    toggleBubbleVisibility(hideShow) {
      if (process.client) {
        window.addEventListener('chatwoot:ready', () => {
          window.$chatwoot.toggleBubbleVisibility(hideShow);
        });
        if (window.$chatwoot) {
          window.$chatwoot?.toggleBubbleVisibility(hideShow);
        }

        if (window.$crisp) {
          $crisp.push(["do", `chat:${hideShow}`]);
        }
      }
    }
  }
};
