

import SpeedkitPicture from 'nuxt-speedkit/components/SpeedkitPicture';
import GetImage from '@/util/get_firebase_images';


import { mapGetters } from "vuex";

export default {
  components: {
    SpeedkitPicture
  },
  data() {
    return {
      picture: {

      },
    };
  },
  props: {
    performer: {
      type: Object,
      default: () => ({})
    },
    to: {
      type: String,
    },
    imageSize: {
      type: Number,
      default: 33
    },
    paddingLeft: {
      type: Number,
      default: 0
    }
  },
  created() {
    this.picture = {
      placeholders: [
        {
          format: 'jpg',
          url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg=='
        }
      ],
      sources: [

        {
          format: 'jpg',
          sizes: [
            // jpg
            { width: 33, url: GetImage.getPerformerMiniImageUrl(this.performer.slug) }
          ]
        }
      ]
    }
  },
  mounted() {
    this.$nextTick(async () => {
      this.picture = {
        placeholders: [
          {
            format: 'jpg',
            url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg=='
          }
        ],
        sources: [

          {
            format: 'jpg',
            sizes: [
              // jpg
              { width: 33, url: GetImage.getPerformerMiniImageUrl(this.performer.slug) }
            ]
          }
        ]
      }


    });
  },
  watch: {
    performer(newVal) {
      this.picture = {
        placeholders: [
          {
            format: 'jpg',
            url: 'data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCAzIDInPjwvc3ZnPg=='
          }
        ],
        sources: [

          {
            format: 'jpg',
            sizes: [
              // jpg
              { width: 33, url: GetImage.getPerformerMiniImageUrl(this.performer.slug) }
            ]
          }
        ]
      }
    }
  }
};
