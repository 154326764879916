/* eslint-disable */
import configVars from "@/config/config_vars";
let appBaseUrl = configVars.appBaseUrl;

import axios from "axios";

import querystring from "querystring";





/**
 *
 * @param {*} param0
 * @param {*} params
 */
var create_auth_token = (payload) => {
  return new Promise((resolve, reject) => {
    //http://localhost:3069/auth/create_auth_token`
    let consulta = `${appBaseUrl}auth/create_auth_token`

    let instance = axios.create();
    instance.defaults.headers.common = {};
    //instance.defaults.timeout = 9000;
    instance
      .post(consulta, payload)
      .then(response => {

        resolve(response.data);
      })
      .catch(error => {
  
        reject(error);
      });
  });


}



const  verifyEmail = (body) => {
  let consulta = `${appBaseUrl}auth/verifyEmail/`;
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  //instance.defaults.timeout = 9000;
  return instance.post(consulta, body)
      
}
const sendVerificationEmail  = (body) => {
  let consulta = `${appBaseUrl}auth/sendVerificationEmail/`;
  let instance = axios.create();
  instance.defaults.headers.common = {};
  //instance.defaults.headers.common["authorization"] = `Bearer ${token}`;
  //instance.defaults.timeout = 9000;
  return instance.post(consulta, body)
      
}




export default {
  create_auth_token,
  verifyEmail,
  sendVerificationEmail

}
