export default {
    data() {
        return {
            senderId: null,
        };
    },
    methods: {

        async handleGoogleResponse() {
            const query = this.$route.query;
            const accessToken = query.access_token;
            const signin_with = query.signin_with;

            if (accessToken && signin_with === 'google') {
                await this.googleMe(accessToken);
                const url = new URL(window.location.href);
                url.searchParams.delete('access_token');
                url.searchParams.delete('signin_with');
                url.searchParams.delete('v');
                window.history.replaceState({}, document.title, url);
            }


        },
        async googleMe(accessToken) {
            try {
                const userResponse = await fetch('https://www.googleapis.com/oauth2/v2/userinfo', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                })


                if (userResponse.ok) {
                    const userData = await userResponse.json();
                    console.log('userData')

                    console.log(userData)

                    const userToSave = {
                        firstName: userData.given_name,
                        lastName: userData.family_name,
                        email: userData.email,
                        password: userData.email,
                        imgSrc: userData.id,
                        music: [],
                        senderId: this.senderId,
                        googleId: userData.id,
                        tipo: "google",

                    };

                    console.log(userToSave)
                    await this.$store.dispatch("user/signup", userToSave);
                    this.handleSuccessfulSignup();
                } else {
                    console.log('Error en la respuesta de Facebook:', userResponse.status, userResponse.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        },
        handleSuccessfulSignup() {

            this.$store.commit("user/signinGetReadyToAddPromo", false);

            const user = this.$store.getters["user/user"];
            if (user) {
                const coordinates = this.$store.getters["coordinates"];
                if (coordinates && coordinates.lat && coordinates.lon) {
                    this.$store.dispatch("user/setUserCoordinates", coordinates);
                }


            }
        }
    }
};
